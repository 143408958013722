import {PublicKey} from "@solana/web3.js"

import * as anchor from "@project-serum/anchor";
import {
    Program, Idl, BN
} from "@project-serum/anchor"

import moneyWheelIdl from "./Programs/money_wheel/idl/money_wheel.json"

export const MONEY_WHEEL_PROGRAM_ID = new PublicKey(moneyWheelIdl.metadata.address)

export interface Slice {
    multiplier: BN,
    multiplierDecimals: BN,
    percentage: BN
}



// Money wheel
export async function getWheelDataPDA(
    program: Program<any>
) {
    let [wheelData] = await anchor.web3.PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode("wheel-data")
      ],
      program.programId
    )
    return wheelData
}
  
export async function getSpinDataPDA(
    program: Program<any>,
    spinIndex: BN
) {
    let [wheelData] = await anchor.web3.PublicKey.findProgramAddress(
      [
        anchor.utils.bytes.utf8.encode("spin-data"),
        spinIndex.toArrayLike(Buffer, 'le', 4)
      ],
      program.programId
    )
    return wheelData
}