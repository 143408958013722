// import React from 'react'
import "./RoutesHandler.css"
import { FC, useEffect, useState } from "react";

import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import {
    WalletDisconnectButton,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import bobosLogo from "./Images/logo512.png"

// import HomeContainer from "./HomeContainer"
import HomeHandler from "./HomeScreen"
import CasinoHandler from "./CasinoHandler"

const authorizedWallets = [
    "FT7gy5BnPZpfkpUKQ7xeCsZVF3QuLof5nMvEF97tzxPH",
    "91rg31SN78JqAU3527TAeVyo2NZdEyc6nbv8NJ4XGYdk",
    "DQXa5Eaf5ZdK4ojvN14iNvmhmTqJXTp72pJKt3BwviP4",

    "5nDCKgUVwmtVyBoT1KLrfU5C1nR2oDNTjNeiEBWnXbai",
    "ELWBNkVREuhjcnG6px8S6HJ8jb4qgyj2H65zkoNsnkUH",
    "CumLpQb9EQkqjwCYfZZ456Yq1CPt1hUSmjWryZkD4Rdp"
]

const RoutesHandler: FC<any> = (props) => {
    const location = useLocation()
    const { connection } = useConnection();
    const wallet = useWallet();

    const [userAuthorized, setUserAuthorized] = useState(false);
    const [inGame, setInGame] = useState(false);

    useEffect(() => {
        setUserAuthorized(wallet.publicKey !== null && authorizedWallets.includes(wallet.publicKey?.toString()))
    }, [wallet])
    
    return(
        <div id="Bow-games">
            {!inGame && <Navbar id="Connection-Navbar" expand="lg">
                <Container>
                    {/* <Navbar.Brand href="/home"><h1 className="text-white text-ussr" style={{fontSize: "5rem", fontWeight: "bold"}}>Bobos</h1></Navbar.Brand> */}
                    <Navbar.Brand href="/">
                        <img
                            src={bobosLogo}
                            width="100"
                            height="auto"
                            className="d-inline-block align-top ms-3"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{background: "white"}} />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto ms-5">
                        <Nav.Link href="/boboverse" className={`text-ussr ${location?.pathname === "/boboverse" ? "text-selected": "text-white"}`}><h2>Boboverse</h2></Nav.Link>
                        <Nav.Link href="/arcade" className={`text-ussr ${location?.pathname === "/arcade" ? "text-selected": "text-white"}`}><h2>Arcade</h2></Nav.Link>
                        {/* <Nav.Link href="/arcade" disabled={!userAuthorized} className={`text-ussr ${location?.pathname === "/arcade" ? "text-selected": userAuthorized ? "text-white": ""}`}><h2>Arcade</h2></Nav.Link> */}

                        {/* {wallet.publicKey !== null && authorizedWallets.includes(wallet.publicKey.toString()) && <Nav.Link href="/dao" className={`text-ussr ${location?.pathname === "/dao" ? "text-selected": "text-white"}`}><h2>DAO</h2></Nav.Link>} */}
                        {/* <Nav.Link href="/tracker" className={`text-ussr ${location?.pathname === "/tracker" ? "text-selected": "text-white"}`}><h2>Tracker</h2></Nav.Link> */}
                        {/* <Nav.Link disabled className="text-ussr" style={{color: "#555555"}}><h2>Tracker</h2></Nav.Link> */}
                        {/* <NavDropdown title="Tools" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
                <div id="navbar" className="d-flex justify-content-center justify-content-sm-end mx-auto mx-lg-3">
                {wallet?.connected && <WalletDisconnectButton className="selected-gradient me-3" />}
                <WalletMultiButton className="selected-gradient" />
            </div>
            </Navbar>}
            <Switch>
                <Redirect exact from='/' to='/boboverse' />
                {/* <Route exact path='/home' children={<HomeContainer connection={connection} wallet={wallet} />}></Route> */}
                <Route exact path='/boboverse' children={<HomeHandler setInGame={setInGame} connection={connection} wallet={wallet} />}></Route>
                <Route exact path='/arcade' children={<CasinoHandler connection={connection} wallet={wallet} />}></Route>
                {/* {wallet.publicKey !== null && authorizedWallets.includes(wallet.publicKey.toString()) && <Route exact path='/dao' children={<DAOVotingHandler connection={connection} wallet={wallet} />}></Route>} */}
                {/* <Route exact path='/tracker' children={<Tracker connection={connection} wallet={wallet} />}></Route> */}
            </Switch>
            <ToastContainer />
        </div>
    )
}


export default RoutesHandler;