import {
    Connection,
    PublicKey
} from "@solana/web3.js"
import * as spl from '@solana/spl-token';

import * as anchor from "@project-serum/anchor";
import {
    Program, Idl, BN, Wallet
} from "@project-serum/anchor"

import { BOW_MINT, TransactionResponse } from "../chainInfo"

import moneyWheelIdl from "./Programs/money_wheel/idl/money_wheel.json"
import {MONEY_WHEEL_PROGRAM_ID, Slice, getWheelDataPDA, getSpinDataPDA} from "./casinoInfo"

import {delay, getProvider, getAssociatedTokenAddress} from "../utils"

export interface SpinWheelResponse {
    transactionResponse: TransactionResponse;
    prizeWon: number;
    multiplier: number;
}

export async function spinMoneyWheel(
    connection: Connection,
    wallet: any,
    chosenBet: number
  ): Promise<SpinWheelResponse> {
    const provider = await getProvider(connection, wallet);
    const program = new Program(moneyWheelIdl as Idl, MONEY_WHEEL_PROGRAM_ID, provider);

    let wheelData = await getWheelDataPDA(program)

    let wheelCurrencyAta = await getAssociatedTokenAddress(wheelData, BOW_MINT, true)
    let playerCurrencyAccount = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: BOW_MINT})).value[0]?.pubkey;
    if (playerCurrencyAccount === undefined) return {transactionResponse: {success: false, error: "Wallet doesn't contain any BOW."}, prizeWon: 0, multiplier: 0};
    let playerCurrentAccountData: any = (await connection.getParsedAccountInfo(playerCurrencyAccount)).value?.data
    const playerCurrencyAmount = playerCurrentAccountData.parsed.info.tokenAmount.uiAmount;
    if (playerCurrencyAmount < chosenBet) return {transactionResponse: {success: false, error: "Wallet doesn't contain enough BOW."}, prizeWon: 0, multiplier: 0};

    let initialWheelData = await program.account.wheelData.fetch(wheelData)
    let house = initialWheelData.house
    let initialNumSpins = initialWheelData.numSpins

    let spinData = await getSpinDataPDA(program, new BN(initialNumSpins))

    // console.log(wheelData.toString())
    // console.log(player1Wallet.publicKey.toString())
    // console.log(authWallet.publicKey.toString())
    // console.log(BOW.toString())
    // console.log(wheelCurrencyAta.toString())
    // console.log(player1BOWAta.toString())
    // console.log(spinData.toString())
    console.log(chosenBet)
    try {
      await program.rpc.spinWheel(new BN(chosenBet), {
        accounts: {
          wheelData,
          player: wallet.publicKey,
          house,

          currency: BOW_MINT,
          wheelCurrencyAta,
          playerCurrencyAccount,

          spinData,

          tokenProgram: spl.TOKEN_PROGRAM_ID,
          associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId
      }
    })
    } catch (error) {
        console.log(error)
        return {transactionResponse: {success: false, error}, prizeWon: 0, multiplier: 0}
    }

    await delay(2000)
    let fetchedSpinData = await program.account.spinData.fetch(spinData)
    console.log(fetchedSpinData)
    let multiplier = Number(fetchedSpinData.multiplier) / Math.pow(10, Number(fetchedSpinData.multiplierDecimals))
    let prizeWon = (Number(fetchedSpinData.spinPrice) / Math.pow(10, 9)) * multiplier
    return {transactionResponse: {success: true, error: null}, prizeWon, multiplier}
}



export async function setupWheel(
    connection: Connection,
    wallet: Wallet,
): Promise<TransactionResponse> {
    const provider = await getProvider(connection, wallet);
    const program = new Program(moneyWheelIdl as Idl, MONEY_WHEEL_PROGRAM_ID, provider);

    let wheelData = await getWheelDataPDA(program)
    let wheelCurrencyAta = await getAssociatedTokenAddress(wheelData, BOW_MINT, true)

    // console.log(wheelData.toString())
    // console.log(authWallet.publicKey.toString())
    // console.log(BOW.toString())
    // console.log(wheelCurrencyAta.toString())
    const spinPrice = new BN(1_000_000_000) // 1 BOW
    const slices: Slice[] = [
        {
        multiplier: new BN(20),
        multiplierDecimals: new BN(2),
        percentage: new BN(43)
        },
        {
        multiplier: new BN(40),
        multiplierDecimals: new BN(2),
        percentage: new BN(28)
        },
        {
        multiplier: new BN(100),
        multiplierDecimals: new BN(2),
        percentage: new BN(13)
        },
        {
        multiplier: new BN(200),
        multiplierDecimals: new BN(2),
        percentage: new BN(7)
        },
        {
        multiplier: new BN(400),
        multiplierDecimals: new BN(2),
        percentage: new BN(4)
        },
        {
        multiplier: new BN(500),
        multiplierDecimals: new BN(2),
        percentage: new BN(4)
        },
        {
        multiplier: new BN(1000),
        multiplierDecimals: new BN(2),
        percentage: new BN(1)
        },
    ]

    try {
    await program.rpc.createWheelData(spinPrice, slices, {
        accounts: {
        wheelData,
        house: wallet.publicKey,

        currency: BOW_MINT,
        wheelCurrencyAta,

        tokenProgram: spl.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId,
        }
    })
    } catch (e) {
        console.log(e)
        return {success: false, error: e}
    }

    const fundAmount = new BN("1000000000000") // 20 million
    let userCurrencyAccount = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: BOW_MINT})).value[0].pubkey;

    try {
        await program.rpc.fundWheelData(fundAmount, {
        accounts: {
            wheelData,
            user: wallet.publicKey,

            currency: BOW_MINT,
            wheelCurrencyAta,
            userCurrencyAccount,

            tokenProgram: spl.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
        }
        })
    } catch (error) {
        console.log(error)
        return {success: false, error}
    }

    return {success: true, error: null}

}