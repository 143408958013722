import { useState, useEffect, useRef } from 'react'
import './HomeScreen.css';

import Game from './Game.js'
// import { getSpecificUserTokens, checkNumBobosLimit, setStakedBobos, setPlayableBoboInfo, SetRewardCalculator } from './onChain/Bridge.js';
import { getVaultData } from './onChain/newStaking';
import { getOwnerNfts } from "./onChain/NewBridge"

import Emitter from './Emitter.js';

import bannerImg from './Images/Backgrounds/gameBannerSmall.webp';

import CapitalCityImg from './Images/Backgrounds/CapitalCity.webp';
import TrainingCampImg from './Images/Backgrounds/TrainingCamp.webp';
import FighterJetImg from './Images/Backgrounds/FighterJet.webp';
import BankImg from './Images/Backgrounds/Bank.webp';
import HibernationStationImg from './Images/Backgrounds/HibernationStation.webp';
import GameModeContainer from "./Components/GameModeContainer.js"
import MELogo from "./Images/Icons/meLogo.png"

import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";

import * as anchor from "@project-serum/anchor";
import { useWallet, useConnection  } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';


require('@solana/wallet-adapter-react-ui/styles.css');

const minNumBobos = 15;
const wlWallets = [
    "91rg31SN78JqAU3527TAeVyo2NZdEyc6nbv8NJ4XGYdk",
    "HMsen7CFW9GQQVSF18DDN2PstSusbrTtMzDGUn2KMvWT",
    "FDxpKezPvir8oDZSQ8Ah88dUUDtTEKSeTRLs3FLTQZk2",
    "9QkreA6FKVKSLRxVPwXYyuydsymdMyVQQCnDAGGMUVfp"
]

function HomeScreen(props) {
    const { setInGame, network, endpoint, wallets } = props
    const { connection } = useConnection()
    const wallet = useWallet();

    const maxBobos = 10;
    const [vaultData, setVaultData] = useState({})
    const [userBobos, setUserNFTs] = useState([]);

    const [showGameModes, setShowGameModes] = useState(false);
    const [showBoboContainer, setShowBoboContainer] = useState(false);

    // const [bobosInfo, setBobosInfo] = useState([]);
    const [walletHasBobos, setWalletHasBobos] = useState(false);
    const [selectedBoboMints, setSelectedBoboMints] = useState([]);
    let getTokensToast;

    const [gameMode, setGameMode] = useState("")
    const [playingGame, setPlayingGame] = useState(false);
    const [isWalletUseable, setWalletUseable] = useState(false);

    useEffect(async () => {
        // setBobosInfo([])
        setUserNFTs([])
        if (wallet !== null && wallet !== undefined && wallet.connected && !wallet.connecting && !wallet.disconnecting) {
            // await setStakedBobos(connection, wallet);
            // let bobosLimit = await checkNumBobosLimit(connection, wallet, 0)
            // setBobosInfo(bobosLimit)
            // setShowGameModes(true)
            let fetchedVaultData = await getVaultData(connection, wallet)
            setVaultData(fetchedVaultData)
            console.log(fetchedVaultData)
            let fetchedUserBobos = await getOwnerNfts(connection, wallet, fetchedVaultData)
            fetchedUserBobos.sort((a, b) => parseInt(a.name.slice(6, a.name.length), 10) > parseInt(b.name.slice(6, b.name.length), 10) ? 1: -1 )
            setUserNFTs(fetchedUserBobos)
            console.log(fetchedUserBobos)
            setShowGameModes(true)
        }
        setWalletUseable(wallet !== null && wallet.connected)
    }, [wallet])

    useEffect(() => {
        !isWalletUseable && setGameMode("")
        setWalletHasBobos(isWalletUseable && userBobos.length > 0)
    }, [isWalletUseable, userBobos])

    useEffect(async () => {
        // Toast event listeners
        Emitter.on('getUserTokens', () => {
            getTokensToast = toast.loading('Fetching your Bobos', {position: "top-right"})
            // setToasts((prevToasts) => ({...prevToasts, getTokens: getTokensToast}))
        })
        Emitter.on('getUserTokensCompleted', () => {
            toast.update(getTokensToast, { render: "Fetched your Bobos", type: "success", isLoading: false, closeOnClick: true, autoClose: 3000})
        })
    }, [])

    useEffect(() => {
        setInGame(playingGame)
    }, [playingGame])

    // async function fetchLimitedBobos() {
    //     let _userBobos = await getUserTokens(endpoint, wallet, maxBobos)
    //     console.log(wallet.publicKey.toString(), _userBobos)
    //     setUserNFTs(_userBobos)
    //     if(_userBobos.length > 0) {
    //         setShowGameModes(false)
    //     }
    // }

    async function fetchUriForSelectedMints() {
        let newUserNfts = userBobos
        const toastId = toast.loading("Fetching Bobos metadata...")
        for (let nft of newUserNfts) {
            if (selectedBoboMints.includes(nft.mint)) {
                try {
                    let uriData = await fetch(nft.uri).then( response => {
                        return response.json();
                    });
                    nft.imageUrl = uriData.image;
                    nft.gameData.playable = true
                } catch (e) {
                    // toast.error("Error while fetching Bobos")
                    toast.update(toastId, {render: "Error while fetching Bobos", type: "error", autoClose: 4000})
                    console.error("Error while fetching Bobos metadata: " + e);
                    return false
                }
                
            }
        }
        // toast.success("Fetched Bobos metadata")
        toast.update(toastId, {render: "Fetched Bobos metadata", type: "success", isLoading: false, autoClose: 4000})
        console.log(newUserNfts)
        setUserNFTs(newUserNfts)
        return true
        // let specificBobos = await getSpecificUserTokens(connection, wallet, selectedBoboMints)
        // console.log(wallet.publicKey.toString(), specificBobos)
        // setUserNFTs(specificBobos)
    }
    function setDefaultBobo() {
        console.log("Set default bobo")
        // let defaultBobo = {"mint":"111111111111111","name":"BOBO #3492","uri":"https://bafybeicobkhmfoxt5xaa3oejffoqxo42mryivmop4wm44xar4wjxhhfnq4.ipfs.dweb.link/1352.json","imageUrl":"https://bafybeie5akgpiux3h2xyddy3u6sn2ikg4zflnk3iffj2tlfrhl6ptriktm.ipfs.dweb.link/1352.png", "stakedData":{"staked":false,"timestamp":0,"withdrawn":0,"rewardMultiplier":0,"rarityBracket":0,"lockedDuration":0,"dailyRate":0,"currentRewards":0},"gameData":{"playable":true,"combatLevel":100,"defenseLevel":100,"xp":0}}

        let defaultBobo = {"mint":"111111111111111111111111111111111111111111","name":"BOBO #0000","uri":"https://bafybeicobkhmfoxt5xaa3oejffoqxo42mryivmop4wm44xar4wjxhhfnq4.ipfs.dweb.link/2800.json","imageUrl":"https://bafybeibrjxymuc6zgmpnhtmt3tyj2za5r6xs3jg3yk63jjqo5m6tirzet4.ipfs.dweb.link/2800.png","stakedData":{"staked":false,"timestamp":0,"withdrawn":0,"rewardMultiplier":0,"rarityBracket":52,"lockedDuration":0,"dailyRate":0,"currentRewards":0},"campData":{"camped":false,"additionalPoints":0,"endDate":0,"trainingType":""},"gameData":{"playable":true,"combatPoints":1,"defensePoints":1,"arenaLevel":1,"branch":"Covert Ops"}}
        setUserNFTs([defaultBobo])
    }


    function selectBoboMint(mint) {
        let newSelectedBoboMints = selectedBoboMints
        if(newSelectedBoboMints.includes(mint)) {
            newSelectedBoboMints.splice(newSelectedBoboMints.indexOf(mint), 1)
        } else {
            newSelectedBoboMints.push(mint)
            if(newSelectedBoboMints.length > maxBobos) {
                newSelectedBoboMints.shift()
            }
        }

        setSelectedBoboMints([...newSelectedBoboMints])
        console.log(selectedBoboMints)
    }

    async function ConfirmGameMode() {
        if(gameMode === "Bank") {
            setDefaultBobo()
            setPlayingGame(true)
        } else {
            setShowBoboContainer(true);
        }
    }

    async function Play() {
        const success = await fetchUriForSelectedMints();
        if (!success) return;
        setShowBoboContainer(false);
        setPlayingGame(true)
    }

    let bobosList = userBobos.map((boboInfo, index) => {
        return <div key={index} className={`bobo-selector col-5 mb-3 p-2 ${selectedBoboMints.includes(boboInfo.mint) ? 'selected' : ''}`} onClick={() => selectBoboMint(boboInfo.mint)}>
            <h3>{boboInfo.name}</h3>
        </div>
    })

    return(
        <div id='HomeScreen'>
            <ToastContainer className='position-absolute' />
            {playingGame && <Game vaultData={vaultData} userBobos={userBobos} endpoint={endpoint} connection={connection} wallet={wallet} isWalletUseable={isWalletUseable} gameMode={gameMode} />}
            <div id='connect-screen' style={{display: 'flex'}} className='flex-column justify-content-center align-items-center'>
                <div id='connect-background'>
                    <img src={bannerImg} className='d-block m-auto'/>
                </div>
                <h1 className='text-ussr title' style={{color: '#ffffff'}}>Bobo's Kingdom</h1>
                {/* <div className={isWalletUseable ? "position-absolute mt-3 mr-3" : "d-none"} style={{top: 0, right: 0}}>
                    <WalletMultiButton children={<h3 className='text-ussr m-0'>Account Info</h3>} className="btn-default" />
                </div> */}
                {/* <h6 className="text-white">Boboverse is currently offline due to maintenance. We'll be back up shortly!</h6> */}
                <button className='btn selected-gradient' disabled={false} onClick={() => setShowGameModes(true)}><h3 className='text-ussr text-white m-0'>Enter Portal</h3></button>
                {/* {isWalletUseable && userBobos.length < minNumBobos && <h1 className="text-danger mt-4" style={{zIndex: 0}}>Soldier, you are not allowed to play.</h1>} */}
            
                {/* <button id='htp-btn' onClick={() => setShowHTP(true)} className='btn selected-gradient position-absolute mb-3 mr-3'><img src={controllerIcon} className='img-fluid' /></button> */}
                <h1 className="position-absolute text-normal text-white mb-5" style={{bottom: 0}}><b>Now On <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://magiceden.io/games/bobos_of_war">Eden Games</a>!</b></h1>
            </div>
            <Modal id="gamemodes-modal" show={showGameModes} onHide={() => setShowGameModes(false)} size="lg" centered>
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h1 className="text-ussr">BOBOVERSE</h1>
                        <h4 className='ml-3'>Select Game Mode</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight: "50vh", overflowY: "scroll"}}>
                    {/* {wlWallets.includes(wallet.publicKey?.toString()) && <GameModeContainer name="Capital City Event" image={CapitalCityImg} isWalletUseable={isWalletUseable} selected={gameMode == "Capital City"} onClickHandler={() => setGameMode("Capital City")} disabled={false} />} */}
                    {/* <GameModeContainer name="Capital City Event" image={CapitalCityImg} isWalletUseable={isWalletUseable} selected={gameMode == "Capital City"} onClickHandler={() => setGameMode("Capital City")} disabled={false} /> */}
                    <GameModeContainer name="Training Camp" image={TrainingCampImg} isWalletUseable={isWalletUseable} selected={gameMode == "Training Camp"} onClickHandler={() => setGameMode("Training Camp")} disabled={false} />
                    <GameModeContainer name="Fighter Jet" image={FighterJetImg} isWalletUseable={isWalletUseable} selected={gameMode == "Fighter Jet"} onClickHandler={() => setGameMode("Fighter Jet")} disabled={false} />
                    <GameModeContainer name="Hibernation Station (Staking)" image={HibernationStationImg} isWalletUseable={isWalletUseable} selected={gameMode == "Hibernation Station"} onClickHandler={() => setGameMode("Hibernation Station")} disabled={false} />
                    <div className='gamemode-container d-flex mx-3 mt-4' onClick={() => setGameMode("Bank")}>
                        <div className={`gamemode-content d-flex w-100 h-100`}>
                            <div className={`gamemode-title ps-3 ${gameMode == "Bank" && "selected-gradient"}`}>
                                <h3 className="text-ussr py-2 m-0">Bank (DeFi)</h3>
                            </div>
                            <div className="gamemode-img position-absolute">
                                <img src={BankImg} className="d-block img-fluid" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end align-items-end">
                    <button disabled={gameMode === ""} className='btn selected-gradient mb-3 mr-3' onClick={() => {ConfirmGameMode(); setShowGameModes(false)}} style={{bottom: 0, right: "20px"}}><h2 className='text-white mb-0'>CONFIRM</h2></button>
                </Modal.Footer>
            </Modal>

            <Modal id="bobos-container" show={showBoboContainer} onHide={() => setShowBoboContainer(false)} size="lg" centered>
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h1 className="text-ussr">BOBOVERSE</h1>
                        <h4 className='ml-3'>Select up to 10 Bobos to play with</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-around" style={{minHeight: "20vh", maxHeight: "70vh", overflowY: "auto"}}>
                        {walletHasBobos ? bobosList: <h3 className="text-center my-auto">Access to the Boboverse is restricted.<br />Buy a bobo on <a target="_blank" rel="noopener noreferrer" href="https://magiceden.io/marketplace/bobos_of_war"><img src={MELogo} style={{width: "50px", height: "auto"}} /></a></h3>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end align-items-end">
                    <button disabled={selectedBoboMints.length === 0} className='btn selected-gradient mb-3 mr-3' onClick={() => Play()} style={{bottom: 0, right: "20px"}}><h2 className='text-white mb-0'>PLAY</h2></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default HomeScreen;