import { PublicKey } from "@solana/web3.js";
import { BN } from '@project-serum/anchor';

export const CANDY_MACHINE_ADDRESS = new PublicKey("5VB5StUrvwMLirDcfrXQDMVLM3GSAdaZ32oLxaHKqiV8")
export const VBOW_MINT = new PublicKey("6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9")
export const BOW_MINT = new PublicKey("BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6")
export const REWARD_MINT = BOW_MINT

export interface VaultData {
    address: PublicKey,
    admin: PublicKey,
    rewardMint: PublicKey,
    rewardDecimals: BN,
    rarityBrackets: BN[],
    rarityMultipliers: BN[], // rarity_bracket_identifier: multiplier
    lockedDurations: BN[], // Duration: multiplier
    durationMultipliers: BN[],
    minLockupPeriod: BN, // in seconds /// Not currently used
    baseRewardRate: BN, // Tokens per second
    overLockMultiplier: BN, // rewards earned after the lock duration get multiplied by this
    overLockMultiplierDecimals: BN,
  
    collectionCandyMachine: PublicKey,
    numStaked: BN
}

export interface NftData {
    mint: PublicKey;
    name: string;
    uri: any;
    imageUrl: string;
    stakedData: StakedData;
    campData: CampData;
    gameData: GameData;
}
export interface StakedData {
    staked: boolean;
    timestamp: number;
    withdrawn: number;
    rewardMultiplier: number;
    rarityBracket: number;
    lockedDuration: number;
    dailyRate: number;
    currentRewards: number;
}
export interface CampData {
    camped: boolean;
    additionalPoints: number;
    endDate: number;
    trainingType: string;
}
  
export interface GameData {
    playable: boolean;
    combatPoints: number;
    defensePoints: number;
    arenaLevel: number;
    xp: number;
    branch: string;
}

export interface TransactionResponse {
    success: boolean;
    error: any
}