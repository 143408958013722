import React from 'react'
import './Game.css';
import Unity, { UnityContext } from "react-unity-webgl";
import { shortenAddress, getBoboInfoFromMint } from './onChain/utils.ts'
import { BN, Idl } from '@project-serum/anchor';

import { PublicKey } from "@solana/web3.js";
// import { getProvider, GetCurrentRewards, GetWithdrawnRewards } from "./onChain/Bridge.js"
import {getDefaultCampData, getMetadataProgram, getBoboGameData } from "./onChain/NewBridge.ts"
// import { stakeToken, unstakeToken, withdrawToken, getMintCurrentRewards } from './onChain/StakingBridge.ts'
import { stakeNFT, unstakeNFT, withdrawRewards, getMintCurrentRewards } from "./onChain/newStaking"
import { sendToCamp, retrieveFromCamp, getAdditionalPoints } from "./onChain/trainingStaking"
import { swapVBOW } from "./onChain/tokenSwap"
import { claimTreasure } from "./onChain/treasureHunt"

import { delay, API_URL } from "./onChain/utils"

import {gsap} from 'gsap'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const CapitalCityUnityContext = new UnityContext({
  loaderUrl: "./gameBuild/Capital City.loader.js",
  dataUrl: "./gameBuild/Capital City.data",
  frameworkUrl: "./gameBuild/Capital City.framework.js",
  codeUrl: "./gameBuild/Capital City.wasm",
});

const BankUnityContext = new UnityContext({
  loaderUrl: "./gameBuild/BankBuild.loader.js",
  dataUrl: "./gameBuild/BankBuild.data",
  frameworkUrl: "./gameBuild/BankBuild.framework.js",
  codeUrl: "./gameBuild/BankBuild.wasm",
});

const TrainingCampUnityContext = new UnityContext({
  loaderUrl: "./gameBuild/TrainingCampBuild.loader.js",
  dataUrl: "./gameBuild/TrainingCampBuild.data",
  frameworkUrl: "./gameBuild/TrainingCampBuild.framework.js",
  codeUrl: "./gameBuild/TrainingCampBuild.wasm",
});

const FighterJet = new UnityContext({
  loaderUrl: "./gameBuild/FighterJetBuild.loader.js",
  dataUrl: "./gameBuild/FighterJetBuild.data",
  frameworkUrl: "./gameBuild/FighterJetBuild.framework.js",
  codeUrl: "./gameBuild/FighterJetBuild.wasm",
});

const HibernationStationUnityContext = new UnityContext({
  loaderUrl: "./gameBuild/HibBuild.loader.js",
  dataUrl: "./gameBuild/HibBuild.data",
  frameworkUrl: "./gameBuild/HibBuild.framework.js",
  codeUrl: "./gameBuild/HibBuild.wasm",
});

let unityContext;

const gameModes = {"Capital City": CapitalCityUnityContext,
                  "Bank": BankUnityContext,
                  "Training Camp": TrainingCampUnityContext,
                  "Fighter Jet": FighterJet,
                  "Hibernation Station": HibernationStationUnityContext}

class Game extends React.Component {
  componentDidMount() {
    this.setState({showGame: true});
    unityContext = gameModes[this.props.gameMode]

    let launchTl = gsap.timeline()
    launchTl.to("#connect-screen", {opacity: 0, duration: 3});
    launchTl.set("#connect-screen", {display: "none"}, '>');

    // Game event listeners
    unityContext.on("progress", (progression) => this.updateLoadProgression(progression));
    unityContext.on("loaded", () => this.setData())


    unityContext.on("StakeBobo", async (mint, days) => {
      await this.stakeBobo(mint, days)
    });
    unityContext.on("UnstakeBobo", async (mint) => {
      await this.unstakeBobo(mint)
    });
    unityContext.on("WithdrawBobo", async (mint) => {
      await this.withdrawBobo(mint)
    });
    unityContext.on("RefreshBoboCurrentRewards", (mint) => {
      let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
      updatedBobo.stakedData.currentRewards = getMintCurrentRewards(this.props.vaultData, updatedBobo.stakedData.timestamp, updatedBobo.stakedData.lockedDuration, updatedBobo.stakedData.rarityBracket, updatedBobo.stakedData.withdrawn)
      unityContext.send("ChainData", "ChangeMintData", JSON.stringify({mint, updatedBobo}));
    })

    
    unityContext.on("TreasureFound", (index) => claimTreasure(this.props.connection, this.props.wallet, new Number(index)))


    unityContext.on("SendToCamp", async (mint, trainingType, playerScore, daysInCamp) => {
      await this.sendBoboToCamp(mint, trainingType, playerScore, daysInCamp)
    })
    unityContext.on("FinishCamp", async (mint) => {
      await this.finishBoboCamp(mint)
    })
    unityContext.on("SaveTrainingCampScore", async (mint, gameMode, score) => {
      console.log(mint, gameMode, score)
      await this.saveTrainingCampScore(mint, score, gameMode)
    })

    unityContext.on("SwapVBOWTokens", async () => {
      toast.info("Converting wallet $vBOW to $BOW")
      let response = await swapVBOW(this.props.connection, this.props.wallet)
      if (response === true) {
        toast.success("Converted $vBOW to $BOW")
      } else {
        toast.error("Failed while converting to $BOW: " + response)
      }
    })
  }

  componentWillUnmount() {
    unityContext.removeAllEventListeners();
  }

  updateLoadProgression(progression) {
    this.setState({loadProgression: Math.ceil(progression * 100)})
    console.log(progression)
  }

  setData() {
    let playerAddress = this.props.isWalletUseable ? shortenAddress(this.props.wallet.publicKey.toString()) : "1111...1111"
    let info = {player: {address: playerAddress, tokensOwned: 0, tokensStaked: 0}, numBobos: this.props.userBobos.length, bobos: this.props.userBobos}
    let jsonData = JSON.stringify(info);
    console.log(jsonData)
    setTimeout(() => {
      unityContext.send("ChainData", "SetData", jsonData);
      console.log("sent")
    }, 1000)
  }

  constructor(props) {
    super(props)
    this.state = {
      loadProgression: 100,
      overlayLoaded: true,
      showGame: false,
      toasts: {}
    }

    this.updateLoadProgression = this.updateLoadProgression.bind(this);
    this.stakeBobo = this.stakeBobo.bind(this);
    this.unstakeBobo = this.unstakeBobo.bind(this);
    this.withdrawBobo = this.withdrawBobo.bind(this);
  }


  async stakeBobo(mint, days) {
    let updateInfo = async () => {
      let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
      updatedBobo.stakedData.staked = true;
      updatedBobo.stakedData.timestamp = Math.floor(Date.now()/1000)
      updatedBobo.stakedData.withdrawn = 0
      updatedBobo.stakedData.lockedDuration = days * 86400;
      // updatedBobo.stakedData.currentRewards = 0
      // updatedBobo.stakedData.rewardMultiplier = 50000

      unityContext.send("ChainData", "ChangeMintData", JSON.stringify({mint, updatedBobo}));
    }

    // let provider = await getProvider(this.props.endpoint, this.props.wallet)
    let response = "";
    try {
      response = await stakeNFT(this.props.connection, this.props.wallet, new PublicKey(mint), this.props.vaultData, days * 86400);
      console.log(response)

      if(response.length === 0) {
        unityContext.send("StakingOperationOverlay", "FinishLoading", "An error occured while staking Bobo")
      } else {
        unityContext.send("StakingOperationOverlay", "FinishLoading", "Staked Bobo " + mint)
        updateInfo();
      }
    } catch (e) {
      // GET FILTERED ERROR MESSAGE
      unityContext.send("StakingOperationOverlay", "FinishLoading", "An error occured while staking Bobo")
    }


  }

  async unstakeBobo(mint) {
    let updateInfo = async (withdrewRewards) => {
      let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
      updatedBobo.stakedData.staked = false;
      updatedBobo.stakedData.timestamp = undefined;
      updatedBobo.stakedData.currentRewards = 0;
      updatedBobo.stakedData.withdrawn += withdrewRewards;

      console.log("UPDATED MINT")
      unityContext.send("ChainData", "ChangeMintData", JSON.stringify({mint, updatedBobo}));
    }

    let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
    let withdrewRewards = getMintCurrentRewards(this.props.vaultData, updatedBobo.stakedData.timestamp, updatedBobo.stakedData.lockedDuration, updatedBobo.stakedData.rarityBracket, updatedBobo.stakedData.withdrawn)

    try {
      let response = await unstakeNFT(this.props.connection, this.props.wallet, new PublicKey(mint), this.props.vaultData);
      console.log(response)

      if(response.length === 0) {
        unityContext.send("StakingOperationOverlay", "FinishLoading", "An error occured while unstaking Bobo")
      } else {
        unityContext.send("StakingOperationOverlay", "FinishLoading", "Unstaked Bobo " + mint + " and withdrew " + (+(Math.round(withdrewRewards + "e+5")  + "e-5")).toString() + " $vBOW")
        updateInfo(withdrewRewards);
      }
    } catch (e) {
      // GET FILTERED ERROR MESSAGE
      unityContext.send("StakingOperationOverlay", "FinishLoading", "An error occured while unstaking Bobo")
    }
    
    
  }
  async withdrawBobo(mint) {
    let updateInfo = async (withdrewRewards) => {
      let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
      updatedBobo.stakedData.currentRewards = 0;
      updatedBobo.stakedData.withdrawn += withdrewRewards;

      console.log("UPDATED MINT")

      unityContext.send("ChainData", "ChangeMintData", JSON.stringify({mint, updatedBobo}));
    }

    let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
    let withdrewRewards = getMintCurrentRewards(this.props.vaultData, updatedBobo.stakedData.timestamp, updatedBobo.stakedData.lockedDuration, updatedBobo.stakedData.rarityBracket, updatedBobo.stakedData.withdrawn)


    try {
      let response = await withdrawRewards(this.props.connection, this.props.wallet, new PublicKey(mint), this.props.vaultData);
      console.log(response)

      if(response.length === 0) {
        unityContext.send("StakingOperationOverlay", "FinishLoading", "An error occured while withdrawing funds")
      } else {
        unityContext.send("StakingOperationOverlay", "FinishLoading", "Withdrew " + (+(Math.round(withdrewRewards + "e+5")  + "e-5")).toString() + " $vBOW") // 5 being the max number of decimal places rewarded (of a total of 9)
        updateInfo(withdrewRewards)
      }
    } catch (e) {
      unityContext.send("StakingOperationOverlay", "FinishLoading", "An error occured while withdrawing funds")
    }

    
  }


  async sendBoboToCamp(mint, trainingType, playerScore, daysInCamp) {
    toast.info("Sending Bobo to Training Camp")
    if (playerScore < 0) {
      playerScore = 0;
    }

    let updateInfo = async () => {
      let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
      updatedBobo.campData.camped = true;
      updatedBobo.campData.additionalPoints = getAdditionalPoints(playerScore, daysInCamp)
      updatedBobo.campData.endDate = Math.floor(Date.now()/1000) + daysInCamp * 86400
      updatedBobo.campData.trainingType = trainingType;

      unityContext.send("ChainData", "ChangeMintData", JSON.stringify({mint, updatedBobo}));
    }

    let response = await sendToCamp(this.props.connection, this.props.wallet, new PublicKey(mint), trainingType, playerScore, daysInCamp)
    console.log(response)
    // delay(5000)
    // let response = true;

    if (response == true) {
      updateInfo()
      toast.success("Sent Bobo to Training Camp for " + daysInCamp.toString() + " days!")
    } else {
      toast.error("An error occured while sending Bobo to Training Camp: " + response)
    }
  }

  async finishBoboCamp(mint) {
    toast.info("Retrieving Bobo from Training Camp")

    let updateInfo = async () => {
      let [updatedBobo, index] = getBoboInfoFromMint(this.props.userBobos, mint)
      updatedBobo.campData = getDefaultCampData()

      const metadataProgram = getMetadataProgram(this.props.connection, this.props.wallet)
      updatedBobo.gameData = getBoboGameData(metadataProgram, mint, updatedBobo.name)

      unityContext.send("ChainData", "ChangeMintData", JSON.stringify({mint, updatedBobo}));
    }

    let response = await retrieveFromCamp(this.props.connection, this.props.wallet, new PublicKey(mint))
    console.log(response)
    // delay(5000)
    // let response = true;

    if (response == true) {
      updateInfo()
      toast.success("Retrieved Bobo from Training Camp!")
    } else {
      toast.error("An error occured while retrieving Bobo from Training Camp: " + response)
    }
  }

  async saveTrainingCampScore(mint, score, gameMode) {
    const timestamp = Math.floor(new Date() / 1000)
    const cutTimestamp = timestamp.toString().substr(-4)
    const id = Math.floor(Math.sqrt(cutTimestamp * 134) + cutTimestamp / 10)
    try {
      const rawResponse = await fetch(`${API_URL}/api/trainingCamp/save-score`, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({boboMint: mint, score, mode: gameMode, timestamp, id})
      });
      let response = await rawResponse.json();
      if (response.success) return
    } catch (err) {console.log(err)}
    setTimeout(() => this.saveTrainingCampScore(mint, score, gameMode), 11_000)
  }

  

  render() {
    let unityCanvasStyle = this.state.loadProgression !== 100 ? {height: '100vh', width: "100vw", opacity: '0'} : {height: '100vh', width: "100vw"}
    
    return (
      <div id="Game" className='d-flex justify-content-center align-items-center'>
        <div id='user-interface' className='d-flex position-absolute justify-content-center align-items-center'>
          {this.state.loadProgression !== 100 && 
            <div className='d-flex flex-column align-items-center'>
              <h1 className='title text-ussr text-center text-white text-block mb-4'>Loading Boboverse...</h1>
              <h1 className='title text-ussr text-center'>{this.state.loadProgression}/100</h1>
              {/* <Spinner animation="border" role="status" style={{fontSize: "3rem", height: "100px", width: "100px"}}>
                <span className="visually-hidden">Loading...</span>
              </Spinner> */}
            </div>
          }
        </div>
        
        {this.state.showGame && <Unity unityContext={unityContext} style={unityCanvasStyle} /> }
      </div>
    );
  }
  
}

export default Game;
