import {
    Connection,
    ConfirmOptions,
    PublicKey,
} from "@solana/web3.js";

import * as anchor from "@project-serum/anchor";
import {
    Program, Provider, Wallet, web3, BN, Idl
} from '@project-serum/anchor';
import * as spl from '@solana/spl-token';

import treasureHuntProgramIdl from "./Programs/TreasureHunt/idl/treasure_hunt.json"
const treasureHuntID = new PublicKey(treasureHuntProgramIdl.metadata.address)

const opts: ConfirmOptions = {
    preflightCommitment: "processed"
}
export async function getProvider(connection: Connection, wallet: Wallet) {
    const provider = new Provider(
      connection, wallet, opts,
    );
    return provider;
}

enum TreasureContent {
    BOW,
    SOL,
    BOBO
}

export async function getTreasureInfoPDA(
    program: Program<any>,
    secret_code: BN
  ): Promise<PublicKey> {
    const [treasureInfoPDA, _] = await anchor.web3.PublicKey.findProgramAddress(
        [
          anchor.utils.bytes.utf8.encode("treasure"),
          new BN(secret_code).toArrayLike(Buffer, 'le', 8),
        ],
        program.programId
      )
    return treasureInfoPDA
}

export async function createTreasure(
    connection: Connection, 
    wallet: Wallet,
    secret_code: BN,
    treasureContentAmount: BN
) {
    let provider = await getProvider(connection, wallet)
    let program = new Program(treasureHuntProgramIdl as Idl, treasureHuntID, provider);

    const treasureInfoPDA = await getTreasureInfoPDA(program, secret_code)
    const treasureContent = TreasureContent.SOL;

    console.log("Sending add treasure transaction")
    await program.rpc.addTreasure(new BN(secret_code), treasureContentAmount,
      {accounts: {
        treasureInfo: treasureInfoPDA,
        creator: wallet.publicKey,

        tokenProgram: spl.TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId,
      }
    }
    )
    console.log("add treasure transaction Success!")

    let treasureInfoAccount = await program.account.treasureInfo.fetch(treasureInfoPDA)
    console.log(treasureInfoAccount)
    
    let treasureAccountInfo = await connection.getAccountInfo(treasureInfoPDA)
    console.log(`Treasure lamports: ${treasureAccountInfo?.lamports}`)
};

const treasureCodes = [new BN(1593327753), new BN(2552239667), new BN(1830147413), new BN(3454642187)]
export async function claimTreasure(
    connection: Connection, 
    wallet: Wallet,
    index: number,
) {
    let provider = await getProvider(connection, wallet)
    let program = new Program(treasureHuntProgramIdl as Idl, treasureHuntID, provider);

    const secretCode = treasureCodes[index]
    const treasureInfoPDA = await getTreasureInfoPDA(program, secretCode)
    // const treasureContent = TreasureContent.SOL;
    let treasureInfoAccount = await program.account.treasureInfo.fetch(treasureInfoPDA)
    console.log("Found " + Number(treasureInfoAccount.contentAmount) * Math.pow(10, -9) + " $SOL in treasure!")

    console.log("Sending claim treasure transaction")
    await program.rpc.claimTreasure(new BN(secretCode), {
        accounts: {
          treasureInfo: treasureInfoPDA,
          claimer: wallet.publicKey,
          creator: new PublicKey("FT7gy5BnPZpfkpUKQ7xeCsZVF3QuLof5nMvEF97tzxPH"),
  
          tokenProgram: spl.TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
    })
    console.log("claim treasure transaction Success!")
};