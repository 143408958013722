import { PublicKey } from "@solana/web3.js"

import boboMetadataIdl from "./Programs/BoboMetadata/idl/bobo_metadata.json"
import trainingCampProgramIdl from "./Programs/TrainingStaking/idl/training_staking.json"
import stakingProgramIdl from './Programs/StakingProgram/idl/nft_staking.json';
import swapVBOWProgramIdl from "./Programs/TokenSwap/idl/token_swap.json"

export const BOBO_METADATA_PROGRAM_ID = new PublicKey(
    boboMetadataIdl.metadata.address
)
export const TRAINING_CAMP_PROGRAM_ID = new PublicKey(
    trainingCampProgramIdl.metadata.address
)

export const STAKING_PROGRAM_ID = new PublicKey(
    stakingProgramIdl.metadata.address
);

export const SWAP_VBOW_PROGRAM_ID = new PublicKey(
    swapVBOWProgramIdl.metadata.address
);