import { PublicKey, Connection, ConfirmOptions } from "@solana/web3.js";
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    Token
} from "@solana/spl-token";
import * as spl from '@solana/spl-token';

import * as anchor from "@project-serum/anchor";
import {
  Program, Provider, Wallet, web3, BN, Idl
} from '@project-serum/anchor';

import { REWARD_MINT } from "./chainInfo";
import { getAssociatedTokenAddress, getCampDataAddress, getTrainingInfoAddress, getAdminInfo, getBoboMetadata } from "./utils"

import { BOBO_METADATA_PROGRAM_ID, TRAINING_CAMP_PROGRAM_ID } from "./programsInfo"
import boboMetadataIdl from "./Programs/BoboMetadata/idl/bobo_metadata.json"
import trainingStakingIdl from "./Programs/TrainingStaking/idl/training_staking.json"

import { programs } from '@metaplex/js';
const { metadata: { Metadata } } = programs;


const opts: ConfirmOptions = {
    preflightCommitment: "processed"
}

export async function getProvider(connection: Connection, wallet: Wallet) {
    const provider = new Provider(
      connection, wallet, opts,
    );
    return provider;
}

export async function sendToCamp (
    connection: Connection,
    wallet: Wallet,
    boboMint: PublicKey,
    trainingType: string,
    score: number,
    daysDuration: number
  ): Promise<any | boolean> {
    const provider = await getProvider(connection, wallet)
    const program = new Program(trainingStakingIdl as Idl, TRAINING_CAMP_PROGRAM_ID, provider);

  
    let campDataAddress = await getCampDataAddress(program)
    let trainingInfoPDA = await getTrainingInfoAddress(program, boboMint)
  
    let metadataInfo = await Metadata.getPDA(boboMint);
  
    let ownerBoboAccount = (await connection.getTokenLargestAccounts(boboMint)).value[0].address;
    let ownerFeeAccount = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: REWARD_MINT})).value[0].pubkey;

    // try {
    //     let fetchedOwnerFeeAccount = await Token.prototype.getAccountInfo(ownerFeeAccount)
    //     console.log(fetchedOwnerFeeAccount)
    //     console.log(fetchedOwnerFeeAccount.amount)
    // } catch (error) {
    //     return error
    // }
  
    let campBoboAta = await getAssociatedTokenAddress(campDataAddress, boboMint, true)
    let campFeeAta = await getAssociatedTokenAddress(campDataAddress, REWARD_MINT, true)

    if (score < 0) {
        score = 0
    }
  
    try {
        await program.rpc.sendToTraining(new BN(score), new BN(daysDuration), trainingType, {
        accounts: {
            campData: campDataAddress,
            trainingInfo: trainingInfoPDA,
            owner: wallet.publicKey,
            boboMint,
            metadataInfo,
            ownerBoboAccount,
            campBoboAta,
    
            feeMint: REWARD_MINT,
            ownerFeeAccount,
            campFeeAta,
    
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId,
        }
        })
    
        // let fetchedTrainingInfo = await program.account.trainingInfo.fetch(trainingInfoPDA)
        // console.log(fetchedTrainingInfo)
    } catch (e) {
        return e
    }

    return true
}
  
export async function retrieveFromCamp (
    connection: Connection,
    wallet: Wallet,
    boboMint: PublicKey
): Promise<any | boolean> {
    const provider = await getProvider(connection, wallet)
    const program = new Program(trainingStakingIdl as Idl, TRAINING_CAMP_PROGRAM_ID, provider);
    const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);
  
    let campDataAddress = await getCampDataAddress(program)
    let trainingInfoPDA = await getTrainingInfoAddress(program, boboMint)
  
    let ownerBoboAta = await getAssociatedTokenAddress(wallet.publicKey, boboMint, true)
    let campBoboAta = await getAssociatedTokenAddress(campDataAddress, boboMint, true)
  
    let metadataAdminInfo = await getAdminInfo(metadataProgram)
  
    let boboMetadata = await getBoboMetadata(metadataProgram, boboMint)
  
    try {
        await program.rpc.endTraining({
        accounts: {
            campData: campDataAddress,
            trainingInfo: trainingInfoPDA,
            owner: wallet.publicKey,
            boboMint,
            ownerBoboAta,
            campBoboAta,
    
            adminInfo: metadataAdminInfo,
            boboMetadata,
            boboMetadataProgram: BOBO_METADATA_PROGRAM_ID,
    
            tokenProgram: spl.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId,
        }
        })
    
        let fetchedBoboMetadata = await metadataProgram.account.boboMetadata.fetch(boboMetadata)
        console.log(fetchedBoboMetadata)
    } catch (error) {
        return error
    }

    return true
}

export function getAdditionalPoints(
    score: number,
    daysInCamp: number
): number {
    let scorePoints = score > 0 ? Math.floor(Math.log(Math.sqrt(score / 1000)) * (daysInCamp / 10)): 0;
    let additionalPoints = Math.floor(daysInCamp / 2) + scorePoints;
    return additionalPoints
}
  