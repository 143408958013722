import {
  Connection,
  MemcmpFilter,
  PublicKey,
  Transaction,
  ConfirmOptions,
  SignatureResult
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import * as spl from '@solana/spl-token';
import {
  Metadata,
} from "@metaplex-foundation/mpl-token-metadata";

import * as anchor from "@project-serum/anchor";
import {
  Program, Wallet, web3, BN, Idl, Provider
} from '@project-serum/anchor';

import { toast } from 'react-toastify';

import idl from './Programs/StakingProgram/idl/nft_staking.json';

import { getAssociatedTokenAddress, getStakingVaultAddress, getStakeDataAddress, getRole } from "./utils"
import { CANDY_MACHINE_ADDRESS, VaultData, StakedData } from "./chainInfo"
import { STAKING_PROGRAM_ID } from "./programsInfo"


const opts: ConfirmOptions = {
  preflightCommitment: "processed"
}

export async function getProvider(connection: Connection, wallet: Wallet) {
  const provider = new Provider(
    connection, wallet, opts,
  );
  return provider;
}

async function sendAndConfirmTransactions(
  connection: Connection,
  wallet: Wallet,
  transactions: Transaction[]
): Promise<SignatureResult[]> {
  let { blockhash } = await connection.getRecentBlockhash("singleGossip");

  transactions.forEach((transaction) => {
    console.log("Transaction tick")
    transaction.feePayer = wallet.publicKey;
    transaction.recentBlockhash = blockhash;
  });

  let signedTransactions: Transaction[] = await wallet.signAllTransactions(transactions);

  let signatures: string[] = await Promise.all(
    signedTransactions.map((transaction) =>
      connection.sendRawTransaction(transaction.serialize(), {
        skipPreflight: true,
      })
    )
  );
  // toast.info("Waiting for confirmation")
  toast.info("Waiting for confirmation")
  let rpcResponses = await Promise.all(
    signatures.map((signature) =>
      connection.confirmTransaction(signature, "processed")
    )
  );
  console.log("Transactions success!")

  return rpcResponses.map(resp => {
    announceResponse(resp.value); return resp.value})
}

function announceResponse(result: SignatureResult) {
  if(result.err === null) {
    toast.success("Transaction successful")
  } else {
    toast.error("An error occured with the transaction")
  }

}

export async function getVaultData(
  connection: Connection,
  wallet: any
): Promise<VaultData | null> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(idl as Idl, STAKING_PROGRAM_ID, provider);

  let vaultDataAddress = await getStakingVaultAddress(program)

  let rewardDecimals = 9;

  let vault_data;
  try {
    let vault_data_account = await program.account.vaultData.fetch(vaultDataAddress)
    vault_data = {
      address: vaultDataAddress,
      admin: vault_data_account.admin as PublicKey,
      rewardMint: vault_data_account.rewardMint as PublicKey,
      rewardDecimals: new BN(rewardDecimals),
      rarityBrackets: vault_data_account.rarityBrackets as BN[],
      rarityMultipliers: vault_data_account.rarityMultipliers as BN[], // rarity_bracket_identifier: multiplier

      lockedDurations: vault_data_account.lockedDurations as BN[], // Duration: multiplier
      durationMultipliers: vault_data_account.durationMultipliers as BN[],
      minLockupPeriod: vault_data_account.minLockupPeriod as BN,
      baseRewardRate: vault_data_account.baseRewardRate,
      overLockMultiplier: vault_data_account.overLockMultiplier as BN,
      overLockMultiplierDecimals: vault_data_account.overLockMultiplierDecimals as BN,
    
      collectionCandyMachine: vault_data_account.collectionCandyMachine as PublicKey,
      numStaked: vault_data_account.numStaked as BN,
    }
  } catch (e) {
    vault_data = null
  }
  
  return vault_data;
}

export async function stakeNFT(
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  vault: VaultData,
  locked_duration: number
): Promise<string> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(idl as Idl, STAKING_PROGRAM_ID, provider);

  let rarity_bracket_identifier = getRole(mint);

  const metadataInfo = await Metadata.getPDA(mint);
  const mintTokenAccount = (await connection.getTokenLargestAccounts(mint)).value[0].address;
  const vaultNftAta = await getAssociatedTokenAddress(
    vault.address,
    mint,
    true
  );
  const stakeData = await getStakeDataAddress(mint, program);

  let response = await program.rpc.stake(new BN(rarity_bracket_identifier), new BN(locked_duration), {
    accounts: {
      payer: wallet.publicKey,
      mint,
      metadataInfo,

      vaultData: vault.address,
      collectionCandyMachine: vault.collectionCandyMachine,
      payerNftAta: mintTokenAccount,
      vaultNftAta,
      stakeData,

      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId
    }})

    return response;
}

export async function stakeAllNFTs(
  connection: Connection,
  wallet: any,
  mints: PublicKey[],
  vault: VaultData,
  locked_duration: number
): Promise<SignatureResult[]> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(idl as Idl, STAKING_PROGRAM_ID, provider);

  async function getStakeTransactionForMint(
      mint: PublicKey
    ): Promise<Transaction> {
    let rarity_bracket_identifier = getRole(mint);

    const metadataInfo = await Metadata.getPDA(mint);
    const mintTokenAccount = (await connection.getTokenLargestAccounts(mint)).value[0].address;
    const vaultNftAta = await getAssociatedTokenAddress(
      vault.address,
      mint,
      true
    );
    const stakeData = await getStakeDataAddress(mint, program);

    return program.transaction.stake(new BN(rarity_bracket_identifier), new BN(locked_duration), {
      accounts: {
        payer: wallet.publicKey,
        mint,
        metadataInfo,

        vaultData: vault.address,
        collectionCandyMachine: vault.collectionCandyMachine,
        payerNftAta: mintTokenAccount,
        vaultNftAta,
        stakeData,

        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId
      }})
    }
  
    let transactions: Transaction[] = [];
    for(let mint of mints) {
      transactions.push(await getStakeTransactionForMint(mint));
    }

    let response = await sendAndConfirmTransactions(connection, wallet, transactions);
    return response;
}

export async function unstakeNFT(
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  vault: VaultData,
): Promise<string> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(idl as Idl, STAKING_PROGRAM_ID, provider);

  let vaultDataAddress = await getStakingVaultAddress(program)

  const metadataInfo = await Metadata.getPDA(mint);
  const payerNftAta = await getAssociatedTokenAddress(
    wallet.publicKey,
    mint
  )
  const vaultNftAta = await getAssociatedTokenAddress(
    vaultDataAddress,
    mint,
    true
  );

  const rewardMint = vault.rewardMint;
  const payerRewardAta = await getAssociatedTokenAddress(
    wallet.publicKey,
    rewardMint
  )
  const vaultRewardAta = await getAssociatedTokenAddress(
    vaultDataAddress,
    rewardMint,
    true
  )
  const stakeData = await getStakeDataAddress(mint, program)

  

  let response = await program.rpc.unstake({
    accounts: {
      payer: wallet.publicKey,
      mint,
      metadataInfo,

      vaultData: vaultDataAddress,
      collectionCandyMachine: vault.collectionCandyMachine,
      payerNftAta,
      vaultNftAta,

      payerRewardAta,
      vaultRewardAta,
      rewardMint,

      stakeData,

      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId
    }})

    return response;
}

export async function withdrawRewards(
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  vault: VaultData,
): Promise<string> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(idl as Idl, STAKING_PROGRAM_ID, provider);

  const metadataInfo = await Metadata.getPDA(mint);

  const rewardMint = vault.rewardMint;
  const payerRewardAta = await getAssociatedTokenAddress(
    wallet.publicKey,
    rewardMint
  )
  const vaultRewardAta = await getAssociatedTokenAddress(
    vault.address,
    rewardMint,
    true
  )

  const stakeData = await getStakeDataAddress(mint, program)

  let response = await program.rpc.withdraw({
    accounts: {
      payer: wallet.publicKey,
      mint,
      metadataInfo,

      vaultData: vault.address,
      collectionCandyMachine: vault.collectionCandyMachine,

      payerRewardAta,
      vaultRewardAta,
      rewardMint,

      stakeData,

      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId
    }})

    return response;
}

export async function withdrawAllRewards(
  connection: Connection,
  wallet: any,
  mints: PublicKey[],
  vault: VaultData
): Promise<SignatureResult[]> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(idl as Idl, STAKING_PROGRAM_ID, provider);

  async function getWithdrawInstructionForMint(
      mint: PublicKey
    ): Promise<Transaction> {
      const metadataInfo = await Metadata.getPDA(mint);

      const rewardMint = vault.rewardMint;
      const payerRewardAta = await getAssociatedTokenAddress(
        wallet.publicKey,
        rewardMint
      );
      const vaultRewardAta = await getAssociatedTokenAddress(
        vault.address,
        rewardMint,
        true
      );
    
      const stakeData = await getStakeDataAddress(mint, program);

      const response: Transaction = program.transaction.withdraw({
        accounts: {
          payer: wallet.publicKey,
          mint,
          metadataInfo,
    
          vaultData: vault.address,
          collectionCandyMachine: vault.collectionCandyMachine,
    
          payerRewardAta,
          vaultRewardAta,
          rewardMint,
    
          stakeData,
    
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId
        }})

        return response;
    }
  
    let transactions: Transaction[] = [];
    for(let mint of mints) {
      transactions.push(await getWithdrawInstructionForMint(mint));
    }

    let response = await sendAndConfirmTransactions(connection, wallet, transactions);
    return response;
}



export function getMintCurrentRewards(
  vault: VaultData,
  startTimestamp: number,
  lockedDuration: number,
  rarityBracket: number,
  withdrawn: number
): number {
  const baseRate = Number(vault.baseRewardRate);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  
  const rarityMultiplier = getRarityMultiplier(vault, rarityBracket)
  const durationMultiplier = getDurationMultiplier(vault, lockedDuration)

  const overLockMultiplier = Number(vault.overLockMultiplier)
  const overLockMultiplierDecimals = Number(vault.overLockMultiplierDecimals);

  let totalRewards = calculate_total_rewards(baseRate, startTimestamp, currentTimestamp, lockedDuration, rarityMultiplier, durationMultiplier, overLockMultiplier, overLockMultiplierDecimals);
  // console.log((totalRewards - Number(withdrawn)) / Math.pow(10, Number(vault.rewardDecimals)))
  return (totalRewards - Number(withdrawn)) / Math.pow(10, Number(vault.rewardDecimals));
}

function calculate_total_rewards(
    base_rate: number,
    start_timestamp: number,
    current_timestamp: number,
    locked_duration: number,
    rarity_multiplier: number,
    duration_multiplier: number,
    over_lock_multiplier: number,
    over_lock_multiplier_decimals: number
  ): number {
  let stake_duration = current_timestamp - start_timestamp;
  let total_rewards = base_rate * stake_duration * rarity_multiplier * duration_multiplier / 10_000;
  
  if (stake_duration > locked_duration) {
      let overlock_rewards = (base_rate * (stake_duration - locked_duration) * rarity_multiplier * duration_multiplier) / 10_000;
      total_rewards -= overlock_rewards - overlock_rewards * over_lock_multiplier / (10 ** over_lock_multiplier_decimals);
  }
  return total_rewards;
}

export function getAllCurrentRewards(
  stakedNfts: StakedData[],
  vault: VaultData
): number {
  let allCurrentRewards = 0;
  for(let stakedNft of stakedNfts) {
    allCurrentRewards += Number(getMintCurrentRewards(vault, stakedNft.timestamp, stakedNft.lockedDuration, stakedNft.rarityBracket, stakedNft.withdrawn));
  }
  return allCurrentRewards;
}


export function getRarityMultiplier(
  vault: VaultData,
  rarityBracket: number
): number {
  let rarityIndex = 0;
  for (let id of vault.rarityBrackets) {
    if(Number(id) == rarityBracket) {
      rarityIndex = vault.rarityBrackets.indexOf(id)
    }
  }
  return Number(vault.rarityMultipliers[rarityIndex])
}
export function getDurationMultiplier(
  vault: VaultData,
  lockedDuration: number
): number {
  let durationIndex = 0;
  for (let id of vault.lockedDurations) {
    if(Number(id) == lockedDuration) {
      durationIndex = vault.lockedDurations.indexOf(id)
    }
  }
  return Number(vault.durationMultipliers[durationIndex])
}


