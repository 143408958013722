import { useMemo } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import RoutesHandler from './RoutesHandler'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faTimes, faBell, faUser } from '@fortawesome/free-solid-svg-icons'

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    getLedgerWallet,
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletExtensionWallet,
    getSolletWallet,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

library.add(faInfoCircle, faTimes, faBell, faUser)



function App() {
    const network = WalletAdapterNetwork.Mainnet;
    // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const endpoint = "https://thrumming-shy-cherry.solana-mainnet.quiknode.pro/0f4b1c8dc5ba35837d42d51f99cfd8e4941d4a5d/"
    // const endpoint = "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/" // Devnet
    // const endpoint = "https://ssc-dao.genesysgo.net/";
    // const endpoint = "https://cold-small-meadow.solana-mainnet.quiknode.pro/4d5ba9470c46ab34821fae34c32664c71ca5c40f/";
    const wallets = useMemo(() => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getLedgerWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network }),
    ], [network]);
    
    return(
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <div id='App'>
                        <RoutesHandler network={network} endpoint={endpoint} wallets={wallets} />
                    </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}

export default App;