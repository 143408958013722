import { 
  PublicKey,
  Connection,
  ConfirmOptions 
} from '@solana/web3.js';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token
} from "@solana/spl-token";

import * as anchor from "@project-serum/anchor";
import { Program, Provider, Wallet } from '@project-serum/anchor';

import {CANDY_MACHINE_ADDRESS} from "./chainInfo"

import ranks from './ranks.json'
import boboBranches from "./boboBranches.json"

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
// export const API_URL = "http://localhost:8080"
export const API_URL = "https://bow-api-czdcwk4zsa-uc.a.run.app"

export const shortenAddress = (address: string, chars = 4): string => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

export const getPubKey = (address: string | PublicKey): PublicKey => {
  if(typeof address === 'string') {
    address = new PublicKey(address);
  }
  return address;
}

const opts: ConfirmOptions = {
  preflightCommitment: "processed"
}

export async function getProvider(connection: Connection, wallet: Wallet) {
  const provider = new Provider(
      connection, wallet, opts,
  );
  return provider;
}

export const getBoboInfoFromMint = (userBobos: any, mint: string): any => {
  for(let i = 0; i < userBobos.length; i++) {
    let bobo = userBobos[i];
    if (bobo.mint == mint) {
      return [bobo, i];
    }
  }
}

export function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve: boolean = false
): Promise<PublicKey> {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}



export async function getStakingVaultAddress(
  program: Program
): Promise<PublicKey> {
  let [vaultDataAddress] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("vault"),
      CANDY_MACHINE_ADDRESS.toBuffer()
    ],
    program.programId
  )

  return vaultDataAddress
}

export async function getAdminInfo(
  program: Program<any>
) {
  let [adminInfo] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("admin-info")
    ],
    program.programId
  )

  return adminInfo
}

export async function getBoboMetadata(
  program: Program<any>,
  boboMint: PublicKey
) {
  let [boboMetadata] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("bobo-metadata"),
      boboMint.toBuffer()
    ],
    program.programId
  )

  return boboMetadata
}



export async function getCampDataAddress(
  program: Program<any>
) {
  let [campDataAddress] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("camp-data")
    ],
    program.programId
  )

  return campDataAddress
}

export async function getTrainingInfoAddress(
  program: Program<any>,
  mint: PublicKey
) {
  const [trainingInfo] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("training-info"),
      mint.toBuffer()
    ],
    program.programId
  )

  return trainingInfo
}


export async function getStakeDataAddress(
  mint: PublicKey,
  program: Program<any>
): Promise<PublicKey> {
  let [stakeDataAddress] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("stake-data"),
      mint.toBuffer()
    ],
    program.programId
  );
  return stakeDataAddress;
}


export async function getPoolInfo(
  program: Program<any>
  ): Promise<anchor.web3.PublicKey> {
  let [poolInfo] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("pool-info")
    ],
    program.programId
  )
  return poolInfo
}




export function getRole(
  token: PublicKey,
): any {
  let boboRanks: any = ranks;
  let boboMint: string = token.toString()
  let role: number = boboRanks[boboMint]
  return role;
}
export function getBranch(
  name: string
): string {
  let branches: any = boboBranches;
  let branch: string = branches[name]
  return branch;
}


export function parseUintLe(data: Uint8Array, offset: number = 0, length: number): bigint {
  let number = BigInt(0);
  for (let i = 0; i < length; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 4);
  return number;
}
