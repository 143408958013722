import {React} from "react"
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function GameModeContainer(props) {
    const {name, image, isWalletUseable, selected, onClickHandler, disabled} = props

    return(
        <div className={`gamemode-container ${(!isWalletUseable || disabled) && "disabled"} d-flex justify-content-center mx-3 mt-4`} onClick={() => isWalletUseable && !disabled && onClickHandler()}>
            <div className={`gamemode-content ${(!isWalletUseable || disabled) && "disabled"} d-flex w-100 h-100`}>
                <div className={`gamemode-title ps-3 ${selected && "selected-gradient"}`}>
                    <h3 className="text-ussr py-2 m-0">{name}</h3>
                </div>
                <div className="gamemode-img position-absolute">
                    <img src={image} className=" img-fluid" />
                </div>
            </div>
            {disabled && <h1 className="position-absolute text-white text-ussr" style={{top: "40%"}}><b>In maintenance</b></h1>}
            {!isWalletUseable && <>
                <WalletMultiButton children={<h3 className='text-ussr m-0'>Connect</h3>} className="selected-gradient position-absolute" style={{top: "50%", width: "fit-content"}} />
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 100, hide: 800 }}
                    overlay={<Tooltip id="button-tooltip" {...props}>
                        <p className="text-center">Wallet connection isn't available on Eden Games <b>yet</b>, connect to <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://play.bobosofwar.com">Boboverse</a> to verify Bobos.</p>
                    </Tooltip>}
                    >
                    <div className="position-absolute mb-2" style={{bottom: 0}}><FontAwesomeIcon icon='info-circle' size="2x" /></div>
                </OverlayTrigger></>}
        </div>
    )
}

export default GameModeContainer;