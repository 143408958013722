import { FC, useState, useEffect } from "react";
import "./CasinoHandler.css"

import {delay} from "./onChain/utils"
import { TransactionResponse, BOW_MINT } from "./onChain/chainInfo"
import { SpinWheelResponse, spinMoneyWheel, setupWheel } from "./onChain/Casino/casino"

import gsap from "gsap"
import { toast } from "react-toastify";
// import { InertiaPlugin } from "gsap/InertiaPlugin";

import wheelSpinButtonImage from "./Images/casino/spin.png"
import wheelCrownImage from "./Images/casino/crown.png"
import wheelBetContainerImage from "./Images/casino/betContainer.png"
import wheelBackground from "./Images/casino/casinoBackground.webp"
import wheelImage from "./Images/casino/wheel.png"
import wheelMascot from "./Images/casino/mascot.png"


import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";


const possibleBets = [1, 5, 10, 50]
const wheelData: Map<number, number[]> = new Map<number, number[]>()
wheelData.set(0.2, [3, 7, 11])
wheelData.set(0.4, [1, 9])
wheelData.set(1, [2, 5])
wheelData.set(2, [4, 10])
wheelData.set(4, [6])
wheelData.set(5, [8])
wheelData.set(10, [0])

const CasinoHandler: FC<any> = (props) => {
    const { connection, wallet } = props

    // useEffect(() => {
    //     // gsap.registerPlugin(InertiaPlugin);

    //     spinWheelSetup()
    // }, [])
    
    const [chosenBet, setChosenBet] = useState(1)
    const [currentWinnings, setCurrentWinnings] = useState({success: false, message: "You haven't spun yet"})
    const [spinningWheel, setSpinningWheel] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [showWinningsTab, setShowWinningsTab] = useState(false)

    // useEffect(() => {
    //     checkWalletBowAmount()
    // }, [wallet])

    // async function checkWalletBowAmount() {
    //     let playerCurrencyAccount = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: BOW_MINT})).value[0]?.pubkey;
    //     if (playerCurrencyAccount === undefined)
    //     console.log((await (await connection.getParsedAccountInfo(playerCurrencyAccount))).value.data.parsed.info.tokenAmount.uiAmount)
    // }

    async function spinWheel() {
        if(spinningWheel || wallet.publicKey === null) return

        let wheel: any = gsap.utils.toArray("#arcade-wheel-prop")[0]
        let active: any = gsap.utils.toArray(".active")[0]
        let currentRotation: number
        let lastRotation: number
        let tolerance: number

        var btnPlay: any = gsap.utils.toArray("#play-btn")[0]

        var getter = gsap.getProperty(wheel);
        // InertiaPlugin.track(wheel, "rotation");

        //  Random degree
        function getRandomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        // var indicatorTL = gsap.timeline();
        gsap.set(wheel, {rotation: 0});
        const wheelRPS = 1
        var spinWheelTL = gsap.timeline({paused: true});

        // indicatorTL
        // .to(active, 0.13, {
        //     rotation: -10,
        //     transformOrigin: "65% 36%",
        //     // ease: Power1.easeOut
        // })

        // .to(active, 0.13, {
        //     rotation: 3,
        //     backgroundColor: "red",
        //     transformOrigin: "65% 36%",
        //     // ease: Power1.easeOut
        // });

        spinWheelTL
        .to(wheel, {
            rotation: 360,
            repeat: -1,
            ease: "none",
            transformOrigin: "50% 50%",
            duration: wheelRPS
            // onUpdate: animateIndicator
        })

        // function animateIndicator() {
        //     currentRotation = Math.round(Number(getter("rotation")));
        //     tolerance = currentRotation - lastRotation;

        //     if (Math.round(currentRotation) % (360 / 12) <= tolerance) {
        //         if (indicatorTL.progress() > 0.3 || indicatorTL.progress() === 0) {
        //         indicatorTL.play(0);
        //         }
        //     }
        //     lastRotation = currentRotation;
        // }

        spinWheelTL.play().timeScale(1)
        // gsap.to(spinWheelTL, {timeScale: 1, duration: 2, ease: "power3.in"});

        let response = await spinWheelChain()
        console.log("got response")

        if (response.transactionResponse.success) {
            // 1s = 290°
            let currentWheelRotation = gsap.getProperty(wheel, "rotation")
            if(typeof currentWheelRotation === "string") currentWheelRotation = parseInt(currentWheelRotation)

            let wheelSlices = wheelData.get(response.multiplier)
            let wheelSlice;
            if(wheelSlices === undefined) wheelSlice = 0
            else wheelSlice = wheelSlices[0]

            const targetWheelRotation = 30 * wheelSlice
            const wheelStopDuration = (360 * 3 + targetWheelRotation - currentWheelRotation) * wheelRPS / 72 //288

            gsap.to(spinWheelTL, {timeScale: 0, duration: wheelStopDuration, ease: "power3.out"});
            console.log("Start despin")
            await delay((wheelStopDuration - 1) * 1000)
            console.log("set winnings")

            // let newWheelRotation = gsap.getProperty(wheel, "rotation")
            // if(typeof newWheelRotation === "string") newWheelRotation = parseInt(newWheelRotation)
            // console.log(newWheelRotation, currentWheelRotation)
            // console.log(newWheelRotation - currentWheelRotation)

            setCurrentWinnings({success: true, message: `You won ${response.prizeWon} $BOW!`})
            return
        }

        spinWheelTL.pause()
        let errorMessage = response.transactionResponse.error.toString()
        if (errorMessage.includes("WalletSignTransactionError")) {setSpinningWheel(false); return}
        setCurrentWinnings({success: false, message: `An error occured: ${errorMessage}`})
    }

    async function spinWheelChain() {
        setSpinningWheel(true)

        // await delay(2000)
        // return {transactionResponse: {success: true, error: ""}, prizeWon: 10, multiplier: 10};

        let response: SpinWheelResponse;
        try {
            response = await spinMoneyWheel(connection, wallet, chosenBet)

            // if(response.transactionResponse.success) {
            //     toast.success(`You won ${response.prizeWon} $BOW!`)
            //     return true
            // }
        } catch (err) {
            console.error(err)
            response = {transactionResponse: {success: false, error: err}, prizeWon: 0, multiplier: 0}
        }

        // toast.error("An error occured: " + response.transactionResponse.error)
        return response
    }
    
    async function createWheel() {
        let response = await setupWheel(connection, wallet)
        if(response.success) {
            toast.success("Created wheel")
            return true
        }

        toast.error(`An error occured: ${response.error}`)
        return false
    }

    useEffect(() => {
        const translatePercentage = 100 - window.innerWidth * 100 / 1920
        gsap.set("#arcade-mascot-prop", {xPercent: -translatePercentage})
    })

    useEffect(() => {
        setShowWinningsTab(true)
        setSpinningWheel(false)
    }, [currentWinnings])

    useEffect(() => {
        setShowWinningsTab(false)
        setShowWarningModal(true)
    }, [])


    return (
        <div id="arcade-container" className="d-flex flex-column">
            <div className="arcade-wheel-container">
                <div className="arcade-wheel-background">
                    <img src={wheelBackground} />
                </div>
                <div id="arcade-wheel-prop" className="arcade-image">
                    <img src={wheelImage} />
                </div>
                <div id="arcade-wheel-spin-btn" className={`arcade-image ${(!spinningWheel && wallet.publicKey !== null) ? "pointer-hover" : "disabled"}`} style={{top: 0, left: "50%"}} onClick={spinWheel}>
                    <img src={wheelSpinButtonImage} />
                </div>
                <div id="arcade-mascot-prop" className="arcade-image" style={{bottom: 0, left: 0}}>
                    <img src={wheelMascot} />
                </div> 
                <div id="arcade-wheel-crown-prop" className="arcade-image">
                    <img src={wheelCrownImage} />
                </div>

                
               
                <div id="arcade-bets-container" className="arcade-image">
                    <img src={wheelBetContainerImage} />
                    <div className={`wheel-bet-token pointer-hover ${chosenBet === possibleBets[0] ? "selected" : ""}`} onClick={() => setChosenBet(possibleBets[0])} style={{left: "12.5%"}}></div>
                    <div className={`wheel-bet-token pointer-hover ${chosenBet === possibleBets[1] ? "selected" : ""}`} onClick={() => setChosenBet(possibleBets[1])} style={{left: "32%"}}></div>
                    <div className={`wheel-bet-token pointer-hover ${chosenBet === possibleBets[2] ? "selected" : ""}`} onClick={() => setChosenBet(possibleBets[2])} style={{left: "51.5%"}}></div>
                    <div className={`wheel-bet-token pointer-hover ${chosenBet === possibleBets[3] ? "selected" : ""}`} onClick={() => setChosenBet(possibleBets[3])} style={{left: "71%"}}></div>
                </div>

                {/* <div className="position-absolute text-white" style={{bottom: 0, left: "50%", transform: "translate(-50%, 0)", background: "rgba(0, 0, 0, 0.8)"}}>The amount of BOW won is random. Phantom transaction details are incorrect.</div> */}
            </div>
            <div className="luckywheel">
        
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="60%" height="60%" viewBox="0 0 729.1 729.1">
                <g id="wheel">
                    <path id="szele" d="M509.6,46.5C333.8-32.8,127.1,45.4,47.9,221.2A349.1,349.1,0,0,0,149.6,638.6C301,758,520.6,732.1,640,580.7a348.9,348.9,0,0,0,.9-430.9,357,357,0,0,0-27.6-31.3A348.6,348.6,0,0,0,509.6,46.5Z" transform="translate(0)" fill="#fff"/>
                    <path d="M500.1,67.5C350-.3,172.9,54.6,87.3,195.3,9.1,323.4,28.8,488.4,134.8,594.7a340.6,340.6,0,0,0,29.1,25.9c141.2,111.7,346.2,87.8,457.9-53.4a326.1,326.1,0,0,0,1-403.3A376.4,376.4,0,0,0,597,134.7,326.5,326.5,0,0,0,500.1,67.5Z" transform="translate(0)" fill="#3f3c42"/>
                    <g>
                    <g>
                        <path d="M201.7,83.1A323.1,323.1,0,0,0,82.8,202.9L365.9,364.7Z" transform="translate(0)" fill="#e8e8e8"/>
                        <path d="M82.8,202.9a326.7,326.7,0,0,0-43,163.2l326.1-1.4Z" transform="translate(0)" fill="#b2d5ea"/>
                        <path d="M364.5,38.6A324.8,324.8,0,0,0,201.7,83.1L365.9,364.7Z" transform="translate(0)" fill="#b2d5ea"/>
                        <path d="M39.8,366.1A323.8,323.8,0,0,0,84.3,528.9L365.9,364.7Z" transform="translate(0)" fill="#e8e8e8"/>
                        <path d="M647.5,200.5A323.8,323.8,0,0,0,527.7,81.6L365.9,364.7Z" transform="translate(0)" fill="#b2d5ea"/>
                        <path d="M527.7,81.6a326.7,326.7,0,0,0-163.2-43l1.4,326.1Z" transform="translate(0)" fill="#e8e8e8"/>
                        <path d="M530.1,646.3A325.3,325.3,0,0,0,649,526.5L365.9,364.7Z" transform="translate(0)" fill="#e8e8e8"/>
                        <path d="M365.9,364.7,692,363.3a324.8,324.8,0,0,0-44.5-162.8Z" transform="translate(0)" fill="#e8e8e8"/>
                        <path d="M649,526.5a326.7,326.7,0,0,0,43-163.2l-326.1,1.4Z" transform="translate(0)" fill="#b2d5ea"/>
                        <path d="M84.3,528.9A324.3,324.3,0,0,0,204,647.8L365.9,364.7Z" transform="translate(0)" fill="#b2d5ea"/>
                        <path d="M367.3,690.8a324.8,324.8,0,0,0,162.8-44.5L365.9,364.7Z" transform="translate(0)" fill="#b2d5ea"/>
                        <path d="M204,647.8a326.8,326.8,0,0,0,163.3,43l-1.4-326.1Z" transform="translate(0)" fill="#e8e8e8"/>
                    </g>
                    <g opacity="0.5">
                        <polygon points="363.7 366 212.8 102 368 363.4 363.7 366"/>
                        <polygon points="363.5 364.6 364.6 60.6 368.4 364.6 363.5 364.6"/>
                        <polygon points="363.8 363.4 516.8 100.7 368.1 365.9 363.8 363.4"/>
                        <polygon points="364.7 362.5 628.5 211.5 367.1 366.9 364.7 362.5"/>
                        <polygon points="365.9 362.2 669.9 363.4 365.9 367.2 365.9 362.2"/>
                        <polygon points="367.1 362.5 629.8 515.6 364.6 366.8 367.1 362.5"/>
                        <polygon points="368 363.4 519 627.3 363.7 366 368 363.4"/>
                        <polygon points="368.4 364.6 367.3 668.6 363.5 364.6 368.4 364.6"/>
                        <polygon points="368.1 365.9 215.1 628.5 363.8 363.4 368.1 365.9"/>
                        <polygon points="367.1 366.9 103.3 517.8 364.7 362.5 367.1 366.9"/>
                        <polygon points="365.9 367.2 61.9 366 365.9 362.2 365.9 367.2"/>
                        <polygon points="364.6 366.8 102 213.8 367.1 362.5 364.6 366.8"/>
                    </g>
                    </g>
                    <g id="szele-2" opacity="0.13">
                    <path d="M365.9,49c174.4.1,315.7,141.6,315.6,316a315.6,315.6,0,0,1-45.1,162.5A320.6,320.6,0,0,1,527.4,636a315.8,315.8,0,0,1-357.1-23.5,353.2,353.2,0,0,1-28.1-25.2c-123-123.5-122.5-323.4,1-446.3A315.3,315.3,0,0,1,347.3,49.6q9.3-.6,18.6-.6m0-12c-6.4,0-12.9.2-19.3.6C166,48.1,28.2,202.9,38.7,383.5a328.1,328.1,0,0,0,267,303.3c177.8,33.3,349-83.9,382.3-261.7S603.9,75.8,425.9,42.6A326.8,326.8,0,0,0,365.9,37Z" transform="translate(0)"/>
                    </g>
                    <g id="kozep">
                    <path d="M397.6,306.2a66.3,66.3,0,0,0-61.2,3.6,59.4,59.4,0,0,0-12.5,9.8,63.4,63.4,0,0,0-9.9,12.5,66,66,0,0,0-3.9,61.2A68.4,68.4,0,0,0,323.6,413c1.9,1.8,3.8,3.5,5.9,5.2a66,66,0,0,0,92.9-92.4,48.8,48.8,0,0,0-5.2-5.9A63.6,63.6,0,0,0,397.6,306.2Z" transform="translate(0)" opacity="0.18" style={{isolation: "isolate"}}/>
                    <path d="M393.1,304.5a66.1,66.1,0,0,0-87.5,87.1,68.4,68.4,0,0,0,13.5,19.7c1.9,1.8,3.9,3.6,5.9,5.2a66.9,66.9,0,0,0,28.8,13.1,68.5,68.5,0,0,0,31.5-1.8,65.8,65.8,0,0,0,45.5-74.9,63.3,63.3,0,0,0-12.9-28.8,74.5,74.5,0,0,0-5.2-6A66.3,66.3,0,0,0,393.1,304.5Z" transform="translate(0)" fill="#fff"/>
                    <path d="M384.4,323.7a42.9,42.9,0,0,0-21.2-3.9,43.6,43.6,0,0,0-20.5,6.3A44.9,44.9,0,0,0,324.8,383a43.9,43.9,0,0,0,9.2,13.4l4,3.6a44.4,44.4,0,0,0,19.6,8.9,43.5,43.5,0,0,0,21.5-1.2,46.1,46.1,0,0,0,18.5-11.1,45.3,45.3,0,0,0,12.6-40,44.4,44.4,0,0,0-8.9-19.6,26.4,26.4,0,0,0-3.5-4A44.2,44.2,0,0,0,384.4,323.7Z" transform="translate(0)" fill="#efefef"/>
                    <path d="M370.4,354.7a9.4,9.4,0,0,0-5.2-1,9.9,9.9,0,0,0-5,1.6,11.6,11.6,0,0,0-2.1,1.6,8.3,8.3,0,0,0-1.6,2.1,9.9,9.9,0,0,0-1.6,5,11.4,11.4,0,0,0,.9,5.2,15,15,0,0,0,2.3,3.3,3.9,3.9,0,0,0,1,.8,10.6,10.6,0,0,0,4.8,2.2,11.5,11.5,0,0,0,5.2-.3,9.9,9.9,0,0,0,4.5-2.7,10.1,10.1,0,0,0,2.8-4.5,11.8,11.8,0,0,0,.3-5.3,10.4,10.4,0,0,0-2.1-4.8l-.9-1A11.9,11.9,0,0,0,370.4,354.7Z" transform="translate(0)" fill="#666"/>
                    </g>
                    <path d="M364.5,31h0a5.5,5.5,0,0,1-5.5-5.5V5.5A5.5,5.5,0,0,1,364.5,0h0A5.5,5.5,0,0,1,370,5.5v20A5.5,5.5,0,0,1,364.5,31ZM370,723.6v-20a5.5,5.5,0,0,0-5.5-5.5h0a5.5,5.5,0,0,0-5.5,5.5v20a5.5,5.5,0,0,0,5.5,5.5h0a5.5,5.5,0,0,0,5.5-5.5ZM703.5,370.1h20.1a5.5,5.5,0,0,0,5.5-5.5h0a5.5,5.5,0,0,0-5.5-5.5H703.5a5.5,5.5,0,0,0-5.4,5.5h0A5.5,5.5,0,0,0,703.5,370.1Zm-698.1,0H25.5a5.5,5.5,0,0,0,5.5-5.5h0a5.5,5.5,0,0,0-5.5-5.5H5.4A5.5,5.5,0,0,0,0,364.6H0A5.5,5.5,0,0,0,5.4,370.1ZM538.7,73.6l10-17.3a5.5,5.5,0,0,0-2-7.5h0a5.5,5.5,0,0,0-7.5,2l-10,17.3a5.2,5.2,0,0,0,1.7,7.3l.3.2h0A5.5,5.5,0,0,0,538.7,73.6ZM189.9,678.3l10-17.3a5.5,5.5,0,0,0-2-7.5h0a5.5,5.5,0,0,0-7.5,2l-10,17.4a5.4,5.4,0,0,0,1.8,7.4h.2a5.5,5.5,0,0,0,7.5-2.1Zm9.8-610L189.6,50.9a5.5,5.5,0,0,0-7.5-2h0a5.5,5.5,0,0,0-2,7.5l10.1,17.4a5.4,5.4,0,0,0,7.4,2h0a5.5,5.5,0,0,0,2.1-7.5ZM548.9,672.7l-10-17.3a5.5,5.5,0,0,0-7.5-2h0a5.5,5.5,0,0,0-2,7.5l10,17.3a5.5,5.5,0,0,0,7.5,2h0A5.5,5.5,0,0,0,548.9,672.7ZM660.4,198.9l17.3-10.1a5.5,5.5,0,0,0,2-7.5h0a5.4,5.4,0,0,0-7.4-2h-.1l-17.4,10a5.4,5.4,0,0,0-2,7.4h.1A5.5,5.5,0,0,0,660.4,198.9ZM56.9,549.8l17.3-10.1a5.5,5.5,0,0,0,2-7.5h0a5.6,5.6,0,0,0-7.5-2L51.3,540.3a5.4,5.4,0,0,0-2,7.4h.1A5.5,5.5,0,0,0,56.9,549.8ZM73.6,190.3l-17.3-10a5.5,5.5,0,0,0-7.5,2h0a5.5,5.5,0,0,0,2,7.5l17.4,10a5.6,5.6,0,0,0,7.5-2h0a5.5,5.5,0,0,0-2.1-7.5ZM678.2,539.4l-17.3-10a5.2,5.2,0,0,0-7.3,1.7l-.2.3h0a5.6,5.6,0,0,0,2,7.5l17.3,10a5.5,5.5,0,0,0,7.5-2h0A5.5,5.5,0,0,0,678.2,539.4Z" transform="translate(0)" fill="#fff"/>
                </g>
                <g className="active">
                    <path d="M709.3,156.5c-11.8-18.1-36.9-22.9-55.8-10.6a41.9,41.9,0,0,0-13.8,14.6l-33.6,60.6a2.3,2.3,0,0,0,0,2.5,2.8,2.8,0,0,0,2.3,1.1l69-6.2a41.6,41.6,0,0,0,18.9-6.6C715.3,199.5,721.1,174.7,709.3,156.5Z" transform="translate(0)" fillOpacity="0.22"/>
                    <path d="M714.3,153.3a39.4,39.4,0,0,0-54.4-11.6,38.8,38.8,0,0,0-13.1,14.2l-31.5,59.2a2.3,2.3,0,0,0,.1,2.5,2.4,2.4,0,0,0,2.2,1.1l66.9-4.8a39.9,39.9,0,0,0,18.3-6.2A39.4,39.4,0,0,0,714.3,153.3Z" transform="translate(0)" fill="#fcfcfc"/>
                    <path d="M683.6,163.4a9.8,9.8,0,0,0-4.1,1.6,8.9,8.9,0,0,0-2.7,3.4,5.4,5.4,0,0,0-.8,2.1,14.9,14.9,0,0,0-.2,2.1,10.2,10.2,0,0,0,1.2,4.2,8.4,8.4,0,0,0,3,3.2,10.1,10.1,0,0,0,3,1.3h1.1a7.8,7.8,0,0,0,4.3-.6,9.2,9.2,0,0,0,3.5-2.6,9.5,9.5,0,0,0,1.9-3.9,9.3,9.3,0,0,0-.1-4.4,9.1,9.1,0,0,0-5.8-6.1l-1.1-.3A8.6,8.6,0,0,0,683.6,163.4Z" transform="translate(0)" fill="#ccc"/>
                    <path d="M684,166.5a4.7,4.7,0,0,0-2.6,1.1,5.5,5.5,0,0,0-1.8,2.2,5,5,0,0,0-.5,1.3,6.6,6.6,0,0,0-.1,1.4,5.4,5.4,0,0,0,.8,2.8,6.4,6.4,0,0,0,1.9,2.1l2,.8h.7a5.9,5.9,0,0,0,5.1-2.1,6.8,6.8,0,0,0,1.2-2.6,5.3,5.3,0,0,0-.1-2.8,4.8,4.8,0,0,0-1.4-2.5,5.1,5.1,0,0,0-2.3-1.5l-.7-.2Z" transform="translate(0)" fill="#666"/>
                </g>
                </svg> */}
            </div>
            {/* <h3 className="text-center text-white">Bet Placed:</h3>
            <div className="d-flex justify-content-center mb-4">
                {possibleBets.map(bet => <button key={bet} className={`btn ${chosenBet === bet ? "selected-gradient": "btn-dark"} text-white mx-3`} onClick={() => setChosenBet(bet)}><h4 className="mb-0">{bet} $BOW</h4></button>)}
            </div>
            {wallet.publicKey !== null && <button id="play-btn" className="btn selected-gradient px-5 mx-auto" onClick={spinWheel} style={{width: "fit-content"}}>
                {spinningWheel ? <Spinner animation="border" role="status" style={{color: "white"}}>
                <span className="visually-hidden">Loading...</span>
                </Spinner>: <h1 className="text-white text-ussr mb-0">Spin!</h1>}
            </button>} */}
            {/* <button id="play-btn" className="btn selected-gradient px-5 mx-auto mt-5" onClick={createWheel} style={{width: "fit-content"}}><h1 className="text-white text-ussr mb-0">Create wheel</h1></button> */}

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showWinningsTab}
                onHide={() => setShowWinningsTab(false)}
                >
                {/* <Modal.Header closeButton>
                </Modal.Header> */}
                <Modal.Body className="d-flex flex-column">
                    <div className="d-flex justify-content-end w-100" onClick={() => setShowWinningsTab(false)}><FontAwesomeIcon className="pointer-hover hover-text-selected-drop" icon={faXmark} size="2x" /></div>
                    <h2 className={`text-center ${currentWinnings.success ? "text-success": "text-error"}`}>{currentWinnings.message}</h2>
                </Modal.Body>
            </Modal>

            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showWarningModal}
                onHide={() => setShowWarningModal(false)}
                >
                <Modal.Body className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-end w-100" onClick={() => setShowWinningsTab(false)}><FontAwesomeIcon className="pointer-hover hover-text-selected-drop" icon={faXmark} size="2x" /></div> */}
                    <h6 className={`text-center text-white`}><FontAwesomeIcon className="pointer-hover text-warning hover-text-selected-drop" icon={faTriangleExclamation} size="2x" /> Beware! <br/>The amount of BOW won is random. Phantom transaction details are incorrect.</h6>
                    <button onClick={() => setShowWarningModal(false)} className="btn btn-success">I understand</button>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default CasinoHandler;