import {
  MemcmpFilter,
  Connection,
  ConfirmOptions,
  PublicKey,
  Keypair,
  Signer,
  Transaction,
  SystemProgram,
  } from "@solana/web3.js";
import {
ASSOCIATED_TOKEN_PROGRAM_ID,
TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import * as anchor from "@project-serum/anchor";
import {
Program, Wallet, web3, BN, Idl
} from '@project-serum/anchor';
import * as spl from '@solana/spl-token';

import {
  Metadata,
  MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";

import { toast } from 'react-toastify';

import { chooseMintUri } from "./gifUris.js"
import { getMintCurrentRewards, getRarityMultiplier, getDurationMultiplier } from "./newStaking"
import { getAdditionalPoints } from "./trainingStaking"
import { getProvider, getAssociatedTokenAddress, getBoboMetadata, getStakeDataAddress, parseUintLe, getBranch } from "./utils"
import { VaultData, NftData, StakedData, CampData, GameData } from "./chainInfo"

import { STAKING_PROGRAM_ID, BOBO_METADATA_PROGRAM_ID, TRAINING_CAMP_PROGRAM_ID } from "./programsInfo"
import stakingProgramIdl from './Programs/StakingProgram/idl/nft_staking.json';
import boboMetadataIdl from "./Programs/BoboMetadata/idl/bobo_metadata.json"
import trainingCampProgramIdl from "./Programs/TrainingStaking/idl/training_staking.json"



export async function getOwnerNfts(
  connection: Connection,
  wallet: Wallet,
  vault: VaultData
): Promise<NftData[]> {
  const walletNfts = await Metadata.findDataByOwner(connection, wallet.publicKey);

  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);

  const nfts = [];
  for (let nft of walletNfts) {
    if (
      nft.data.creators &&
      nft.data.creators[0]?.verified &&
      vault.collectionCandyMachine !== undefined &&
      nft.data.creators[0].address === vault.collectionCandyMachine.toString()
    ) {
      let defaultStakedData: StakedData = getDefaultStakedData()

      let defaultCampData: CampData = getDefaultCampData()

      let nftData: NftData = {
        mint: new PublicKey(nft.mint),
        name: nft.data.name,
        uri: chooseMintUri(nft.data.uri, nft.data.name),
        imageUrl: "",
        stakedData: defaultStakedData,
        campData: defaultCampData,
        gameData: await getBoboGameData(metadataProgram, new PublicKey(nft.mint), nft.data.name)
      }
      
      nfts.push(nftData);
    }
  }

  let stakedNfts = await getStakedNftsByOwner(connection, wallet, wallet.publicKey, vault)
  nfts.push(...stakedNfts)
  let campedNfts = await getCampedNftsByOwner(connection, wallet, wallet.publicKey)
  nfts.push(...campedNfts)

  return nfts
}

export async function getStakedNftsByOwner(
  connection: Connection,
  wallet: any,
  owner: PublicKey,
  vault: VaultData
): Promise<NftData[]> {
  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);
  const stakingProgram = new Program(stakingProgramIdl as Idl, STAKING_PROGRAM_ID, provider);

  let stakeDataAccounts = await connection.getProgramAccounts(STAKING_PROGRAM_ID, {
    filters: [
      createStakeTokenOwnerFilter(owner),
    ],
  });

  let mintPDAs = await Promise.all(stakeDataAccounts.map(async ({ account: { data } }) => {
    let mint = new PublicKey(data.slice(44, 76));
    return await Metadata.getPDA(mint)
  }))

  let metadatas = await Metadata.getInfos(connection, mintPDAs)

  

  return Promise.all(
    stakeDataAccounts.map(async ({ account: { data } }, index) => {
      let timestamp = Number(parseUintLe(data, 8, 4));
      let mint = new PublicKey(data.slice(44, 76));
      let metadataAccount = metadatas.get(mintPDAs[index])
      let metadata;
      if(metadataAccount?.data != undefined) {
        metadata = MetadataData.deserialize(metadataAccount?.data)
      }

      let stakeDataInfo = await getStakeDataAddress(mint, stakingProgram)
      let stakeData = await stakingProgram.account.stakeData.fetch(stakeDataInfo)

      let rarityBracket = stakeData.rarityBracketIdentifier;
      let lockedDuration = stakeData.lockedDuration;

      const rarityMultiplier = getRarityMultiplier(vault, rarityBracket)
      const durationMultiplier = getDurationMultiplier(vault, lockedDuration)
      let rewardMultiplier = rarityMultiplier * durationMultiplier / (100 * 100)

      let withdrawn = Number(parseUintLe(data, 82, 8));

      let dailyRate = Number(vault.baseRewardRate) * 86400 * rewardMultiplier / Math.pow(10, Number(vault.rewardDecimals))

      let stakedData = {
        staked: true,
        timestamp: stakeData.entryTimestamp,
        withdrawn,
        rewardMultiplier,
        rarityBracket,
        lockedDuration,
        dailyRate,
        currentRewards: getMintCurrentRewards(vault, stakeData.entryTimestamp, lockedDuration, rarityBracket, withdrawn)
      }

      let defaultCampData: CampData = getDefaultCampData()

      return {
        mint,
        name: metadata.data.name,
        uri: chooseMintUri(metadata.data.uri, metadata.data.name),
        imageUrl: "",
        stakedData,
        campData: defaultCampData,
        gameData: await getBoboGameData(metadataProgram, new PublicKey(mint), metadata.data.name)
      };
    })
  );
}

export async function getCampedNftsByOwner(
  connection: Connection,
  wallet: any,
  owner: PublicKey,
): Promise<NftData[]> {
  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);
  const trainingProgram = new Program(trainingCampProgramIdl as Idl, TRAINING_CAMP_PROGRAM_ID, provider);

  let trainingInfoAccounts = await connection.getProgramAccounts(TRAINING_CAMP_PROGRAM_ID, {
    filters: [
      createStakeTokenOwnerFilter(owner, 40),
    ],
  });

  let mintPDAs = await Promise.all(trainingInfoAccounts.map(async ({ account: { data } }) => {
    let mint = new PublicKey(data.slice(8, 40));
    return await Metadata.getPDA(mint)
  }))

  let metadatas = await Metadata.getInfos(connection, mintPDAs)

  

  return Promise.all(
    trainingInfoAccounts.map(async ({ account: { data }, pubkey }, index) => {
      let mint = new PublicKey(data.slice(8, 40));
      let score = Number(parseUintLe(data, 72, 2));
      // let entryTime = Number(parseUintLe(data, 74, 4));
      let daysInCamp = Number(parseUintLe(data, 78, 1));
      let trainingType = Number(parseUintLe(data, 79, 1));

      // TEMPORARY 
      let fetchedTrainingInfo = await trainingProgram.account.trainingInfo.fetch(pubkey)
      let entryTime = Number(fetchedTrainingInfo.entryTime);

      let metadataAccount = metadatas.get(mintPDAs[index])
      let metadata;
      if(metadataAccount?.data != undefined) {
        metadata = MetadataData.deserialize(metadataAccount?.data)
      }

      let endDate = entryTime + daysInCamp * 86400
      console.log(score)
      console.log(entryTime)
      console.log(daysInCamp)
      console.log(trainingType)

      let campData: CampData = {
        camped: true,
        additionalPoints: getAdditionalPoints(score, daysInCamp),
        endDate,
        trainingType: trainingType === 0 ? "Attack" : "Defense"
      }

      return {
        mint,
        name: metadata.data.name,
        uri: chooseMintUri(metadata.data.uri, metadata.data.name),
        imageUrl: "",
        stakedData: getDefaultStakedData(),
        campData,
        gameData: await getBoboGameData(metadataProgram, new PublicKey(mint), metadata.data.name)
      };
    })
  );
}

function getDefaultStakedData(): StakedData {
  return {
    staked: false,
    timestamp: 0,
    withdrawn: 0,
    rewardMultiplier: 0,
    rarityBracket: 0,
    lockedDuration: 0,
    dailyRate: 0,
    currentRewards: 0
  }
}
export function getDefaultCampData(): CampData {
  return {
    camped: false,
    additionalPoints: 0,
    endDate: 0,
    trainingType: ""
  }
}


export async function getBoboGameData(
  metadataProgram: Program,
  boboMint: PublicKey,
  name: string
): Promise<GameData> {
  let boboMetadata = await getBoboMetadata(metadataProgram, boboMint)

  let fetchedBoboMetadata = await metadataProgram.account.boboMetadata.fetch(boboMetadata)
  let gameData: GameData = {
    playable: false,
    combatPoints: fetchedBoboMetadata.combatPoints,
    defensePoints: fetchedBoboMetadata.defensePoints,
    arenaLevel: fetchedBoboMetadata.arenaLevel,
    xp: fetchedBoboMetadata.arenXp,
    branch: getBranch(name)
  }

  return gameData
}

export function createStakeTokenOwnerFilter(owner: PublicKey, offset: number = 12): MemcmpFilter {
  return {
    memcmp: {
      offset,
      bytes: owner.toBase58(),
    },
  };
}

export async function getMetadataProgram(
  connection: Connection,
  wallet: Wallet,
): Promise<Program> {
  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);
  return metadataProgram
}


// async function createAndSendClaimEggTransaction(
//     connection: Connection, 
//     wallet: Wallet,
//     program: Program,
//     secretCode: BN,
//     easterEggPDA: PublicKey,
//     easterEggInfo: any,


//     sendTransaction: Function,
//     confirmOptions?: ConfirmOptions,
//     tokenProgramId = TOKEN_PROGRAM_ID,
//     associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
// ): Promise<string> {
//     let boboMint = easterEggInfo.boboMint;

//     const [programBoboAta, __] = await anchor.web3.PublicKey.findProgramAddress(
//         [
//           boboMint.toBuffer()
//         ],
//         program.programId
//     )

//     const claimerBoboAta = await getAssociatedTokenAddress(wallet.publicKey, boboMint, false)
  
//     const claimerBoboAtaAccount = await connection.getAccountInfo(claimerBoboAta)
  
//     const transaction = new Transaction()
//     if(!claimerBoboAtaAccount) {
//       console.log("Creating ATA for claimer: " + claimerBoboAta.toString())
//       transaction.add(
//         spl.Token.createAssociatedTokenAccountInstruction(
//           associatedTokenProgramId,
//           tokenProgramId,
//           boboMint,
//           claimerBoboAta,
//           wallet.publicKey,
//           wallet.publicKey,
//         )
//       );
//     }

//     const claimEasterEggBoboTransaction = program.instruction.claimEasterEgg(secretCode, {
//         accounts: {
//           easterEgg: easterEggPDA,
//           claimer: wallet.publicKey,
//           claimerBoboAta,
//           programBoboAta,
//           boboMint,
//           maker: easterEggInfo.maker,
  
//           tokenProgram: spl.TOKEN_PROGRAM_ID,
//           rent: anchor.web3.SYSVAR_RENT_PUBKEY,
//           systemProgram: anchor.web3.SystemProgram.programId,
//         }
//       })
  
//     transaction.add(claimEasterEggBoboTransaction)
  
//     return await sendAndConfirmTransaction(connection, wallet.publicKey, sendTransaction, transaction);
// }
  
  
async function sendAndConfirmTransaction(
    connection: Connection,
    payer: PublicKey,
    sendTransaction: Function,
    transaction: Transaction,
    ): Promise<string> {
    let { blockhash } = await connection.getRecentBlockhash();
    transaction.feePayer = payer!;
    transaction.recentBlockhash = blockhash;

    console.log("sending transaction")
    toast.info("Waiting for signature...", {bodyClassName: "toast-content"})
    // console.log(transaction)
    // console.log(connection)
    try {
      let signature = await sendTransaction(transaction, connection);

      await connection.confirmTransaction(signature, "confirmed");
      console.log(signature);
      return signature;
    } catch (err) {}
    return ""
}